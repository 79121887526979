/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from "react";

import { Typography } from "antd";
import { useTheme } from "@/hooks/useTheme";
import { useRemoteConfig } from "@/redux/slices/remoteConfigSlice";
import { useConnectionSettings } from "@/redux/slices/connectionSettingsSlice";
import { PACKAGE_VERSION } from "@/config/constants";

const { Title, Text } = Typography;

export const LoginContainer = ({
  children,
}: {
  children?: React.ReactNode;
}): React.ReactElement => {
  const theme = useTheme();
  const { title } = useRemoteConfig();
  const { currentHost } = useConnectionSettings();

  return (
    <div
      className="flex flex-col justify-center min-h-screen py-12 sm:px-6 lg:px-8"
      style={{ backgroundColor: theme.colorPrimaryBg }}
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Title
          className="mt-6 text-3xl font-extrabold text-center"
          style={{ color: theme.colorPrimaryText }}
        >
          {title}
        </Title>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div
          className="px-4 py-8 bg-white shadow sm:px-10 flex justify-center w-full"
          style={{ borderRadius: theme.borderRadius }}
        >
          {children}
        </div>
        <Text
          type="secondary"
          className="flex justify-center w-full pt-6 text-xs leading-normal text-center"
        >
          {`Client version: ${PACKAGE_VERSION}`}
        </Text>
        <Text
          type="secondary"
          className="flex justify-center w-full text-xs leading-normal text-center"
        >
          {currentHost}
        </Text>
      </div>
    </div>
  );
};
