import { Alert, Button, Layout, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { useHydrateContext } from "@/hooks/useHydrateContext";
import { useHydrateUser } from "@/hooks/useHydrateUser";
import { useUserId } from "@/hooks/useUserId";
import { ErpFeatureKeys, RootView } from "@gisce/react-ooui";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { useHotkeys } from "react-hotkeys-hook";
import { LoginContainer } from "../Login/LoginContainer";
import { MainContent } from "./MainContent";
import { OmnisearchModal } from "@/components/common/OminsearchModal";
import { useErpState } from "@/hooks/erp/useErp";
import { useNavigate } from "react-router-dom";
import { setNavigate } from "@/services/navigateService";
import { getErrorMessage } from "@/helpers/error";
import { useFeatureEnabled } from "@/hooks/useErpFeatures";
import { CustomHostsOptions } from "../Login/CustomHosts/CustomHostsOptions";
import { useRemoteConfig } from "@/redux/slices/remoteConfigSlice";
import { UpdateNotification } from "@/components/common/UpdateNotification";

function Main(): React.ReactElement {
  const rootViewRef = useRef();
  const userId = useUserId();
  const navigate = useNavigate();
  const { allowCustomHosts } = useRemoteConfig();

  const [
    { data: context, error: fetchContextError },
    fetchHydrateContext,
    cancelHydrateContextRequest,
  ] = useHydrateContext();
  const [
    { data: user, error: fetchUserError },
    fetchHydrateUser,
    cancelHydrateUserRequest,
  ] = useHydrateUser();
  const [launcherVisible, setLauncherVisible] = useState<boolean>(false);
  const initialActionLaunched = useRef<boolean>(false);

  const [initialActionResult, fetchInitialAction, cancelInitialActionRequest] =
    useErpState("readObjects");

  const { data: initialActionData } = initialActionResult;

  const omnisearchEnabled = useFeatureEnabled(
    ErpFeatureKeys.FEATURE_OMNISEARCH,
  );

  useEffect(() => {
    return () => {
      cancelHydrateContextRequest();
      cancelHydrateUserRequest();
      cancelInitialActionRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userId) {
      return;
    }
    void fetchHydrateContext();
    void fetchHydrateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  useHotkeys(
    ["ctrl+space", "alt+space"],
    (event) => {
      event.stopPropagation();
      omnisearchEnabled &&
        setLauncherVisible((launcherVisible) => !launcherVisible);
    },
    {
      enableOnFormTags: true,
      preventDefault: true,
    },
    [omnisearchEnabled, launcherVisible],
  );

  useHotkeys(
    "esc",
    () => {
      setLauncherVisible(false);
    },
    {
      enableOnFormTags: true,
    },
    [],
  );

  useEffect(() => {
    if (context && user && !initialActionLaunched.current) {
      initialActionLaunched.current = true;

      const { action_id: action_id_m2o, menu_id: menu_id_m2o } = user;
      const [menu_id] = menu_id_m2o;
      const [action_id] = action_id_m2o;

      if (menu_id !== action_id) {
        void fetchInitialAction({
          model: "ir.actions.actions",
          ids: [user.action_id?.[0]],
          context,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, user]);

  useEffect(() => {
    if (initialActionData?.[0] && user) {
      const { type } = initialActionData[0];
      (rootViewRef.current as any).retrieveAndOpenAction({
        action: `${type as string},${user.action_id as string}`,
      });
    }
  }, [initialActionData, user]);

  if (fetchUserError || fetchContextError) {
    return (
      <LoginContainer>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Alert
            message={
              <>
                {getErrorMessage(fetchUserError) ||
                  getErrorMessage(fetchContextError)}
              </>
            }
            type="error"
            showIcon
          />
          {allowCustomHosts && (
            <>
              <CustomHostsOptions />
              <Button
                type="primary"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </Button>
            </>
          )}
        </div>
      </LoginContainer>
    );
  }

  if (user && context) {
    return (
      <ErrorBoundary>
        {omnisearchEnabled && (
          <OmnisearchModal
            visible={launcherVisible}
            onLaunch={(action) => {
              setLauncherVisible(false);
              (rootViewRef.current as any).retrieveAndOpenAction({
                action,
              });
            }}
            onClose={() => {
              setLauncherVisible(false);
            }}
          />
        )}
        <Layout className="bg-white">
          <UpdateNotification />
          <RootView ref={rootViewRef}>
            <MainContent rootViewRef={rootViewRef} />
          </RootView>
        </Layout>
      </ErrorBoundary>
    );
  }

  return (
    <LoginContainer>
      <Spin />
    </LoginContainer>
  );
}

export default Main;
