import { useCallback, useEffect, useState } from "react";
import useWindowDimensions from "./useWindowDimensions";
import { useRemoteConfig } from "@/redux/slices/remoteConfigSlice";

const SIDEBAR_WIDTH_BREAKPOINT = 1024;

export const useSidebarCollapsed = () => {
  const { width } = useWindowDimensions();
  const widthBreakpointFired = width <= SIDEBAR_WIDTH_BREAKPOINT;
  const { disableAutoCollapseMenu } = useRemoteConfig();

  const [sidebarCollapsed, setSidebarCollapsed] =
    useState(widthBreakpointFired);

  useEffect(() => {
    // If it's collapsed, let it be collapsed
    // if (sidebarCollapsed) {
    //   return;
    // }

    if (widthBreakpointFired) {
      setSidebarCollapsed(true);
    } else if (!widthBreakpointFired) {
      setSidebarCollapsed(false);
    }
  }, [widthBreakpointFired]);

  const toggleSidebarCollapsed = useCallback(() => {
    setSidebarCollapsed((prevSidebarCollapsed) => !prevSidebarCollapsed);
  }, []);

  const closeSidebarIfNeeded = useCallback(() => {
    if (disableAutoCollapseMenu) {
      return;
    }

    setSidebarCollapsed(true);
  }, [disableAutoCollapseMenu]);

  return {
    sidebarCollapsed,
    toggleSidebarCollapsed,
    widthBreakpointFired,
    closeSidebarIfNeeded,
  };
};
