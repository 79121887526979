import ca_ES from "@/locale/ca_ES";
import en_US from "@/locale/en_US";
import es_ES from "@/locale/es_ES";
import { useUserInfo } from "@/redux/slices/userInfoSlice";
import { useMemo } from "react";
import { Locale } from "@gisce/react-formiga-components";

export const strings: Record<Locale, Record<string, string>> = {
  ca_ES,
  en_US,
  es_ES,
};

const SUPPORTED_LOCALES: Locale[] = ["en_US", "ca_ES", "es_ES"];
const FALLBACK_LOCALE: Locale = "es_ES";

// Browser lang comes formatted with IETF BCP 47
// We need to convert it to POSIX, that's the format we use internally
const browserLang: Locale = (
  (window.navigator as any).userLanguage ||
  window.navigator.language ||
  FALLBACK_LOCALE
).replace("-", "_");

export const useLang = () => {
  const { context } = useUserInfo();

  const lang: Locale = useMemo(
    () => evaluateNewLang(context?.lang ? context.lang : browserLang),
    [context?.lang],
  );

  return {
    lang,
  };
};

function evaluateNewLang(newLang: Locale) {
  // Check if the exact locale is supported
  if (SUPPORTED_LOCALES.includes(newLang)) {
    return newLang;
  }

  // Extract the language part (before the underscore)
  const languagePart: string = newLang.split("_")[0];

  // Find a supported locale that starts with the same language part
  const matchingLocale = SUPPORTED_LOCALES.find((locale) =>
    locale.startsWith(`${languagePart}_`),
  );

  // Return the matching locale or the FALLBACK_LOCALE if no match is found
  return matchingLocale || FALLBACK_LOCALE;
}
