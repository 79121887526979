import { Context, User } from "@/types/base";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";

interface UserInfoState {
  context: Context | null;
  user: User | null;
}

const initialState: UserInfoState = {
  context: null,
  user: null,
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setContext: (state, action: PayloadAction<Context>) => {
      state.context = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
  },
});

export const { setContext, setUser } = userInfoSlice.actions;

export default userInfoSlice.reducer;

export const useUserInfo = () => {
  return useSelector((state: RootState) => state.userInfo);
};
