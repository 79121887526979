import React from "react";

type Props = {
  children: React.ReactNode;
};

function Menu(props: Props): React.ReactElement {
  const { children } = props;
  return (
    <div className="pl-2 pt-2 pr-5 pb-20">
      <ul>{children}</ul>
    </div>
  );
}

export default Menu;
