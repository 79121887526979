import { useMenuContext } from "@/components/menu/menuContext";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { theme } from "antd";
import React from "react";
const { useToken } = theme;

type SubMenuExtraProps = {};

export type MenuEntryProps = {
  id: string;
  title: string;
  children?: React.ReactNode;
  onClick: (key: string) => void;
  icon?: React.ReactElement;
  color: string;
};

export type SubMenuEntryProps = MenuEntryProps & SubMenuExtraProps;

type Expandable = {
  isExpandable: boolean;
};

type Props = MenuEntryProps & SubMenuExtraProps & Expandable;

const BaseMenuEntry = (props: Props): React.ReactElement => {
  const { title, id, children, onClick, icon, isExpandable, color } = props;
  const { token } = useToken();

  const { toggleOpenedKey, keyIsOpened } = useMenuContext();

  const getChevron = () => {
    if (!isExpandable) {
      return;
    }
    const ChevronIcon = keyIsOpened(id) ? DownOutlined : RightOutlined;
    return (
      <ChevronIcon
        className="pr-2 text-xs"
        style={{ color: token.colorPrimaryActive }}
        onClick={handleToggleExpand}
      />
    );
  };

  const getIndentStyle = () => {
    const level = id.split("-").length - 1;
    const padding = (isExpandable ? 0 : 1.25) + level * 0.7;
    return { paddingLeft: `${padding}rem` };
  };

  const handleToggleExpand = () => {
    toggleOpenedKey(id);
  };

  const handleOnTitleClick = () => {
    if (isExpandable) {
      toggleOpenedKey(id);
    }
  };

  const handleOnTitleDoubleClick = () => {
    if (!isExpandable) {
      onClick(id);
      return;
    }

    if (!keyIsOpened(id)) {
      toggleOpenedKey(id);
    }

    onClick(id);
  };

  const getChildren = () => {
    if (!keyIsOpened(id)) {
      return;
    }
    return <ul>{children}</ul>;
  };

  return (
    <li>
      <div className="flex items-center h-7" style={getIndentStyle()}>
        {getChevron()}
        <div
          className="items-center flex-grow min-w-0"
          onClick={handleOnTitleClick}
          onDoubleClick={handleOnTitleDoubleClick}
        >
          <div className="flex items-center cursor-pointer select-none">
            {icon}
            <span
              className="col-span-11 pl-2 text-xs leading-loose truncate"
              style={{ color }}
            >
              {title}
            </span>
          </div>
        </div>
      </div>
      {getChildren()}
    </li>
  );
};

export default BaseMenuEntry;
