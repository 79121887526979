import { useLocale } from "@gisce/react-formiga-components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, Modal } from "antd";
import { useCallback, useEffect } from "react";
const { error } = Modal;

export type TrackingModalProps = {
  visible: boolean;
  onAccept: (recordingName: string) => void;
  onCancel: () => void;
};

export const TrackingModal = (props: TrackingModalProps): any => {
  const { visible, onAccept, onCancel } = props;
  const { t } = useLocale();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!form) {
      return;
    }
    form.setFieldsValue({
      recordingName: "",
      tosAccepted: undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onFormFinish = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (verror) {
      return;
    }
    const values = form.getFieldsValue();

    const showError = () =>
      error({
        title: "Error",
        icon: <ExclamationCircleOutlined />,
        centered: true,
        content: t("recordingMustAcceptTos") + " " + t("recordingTerms"),
      });

    if (!values.tosAccepted) {
      showError();
      return;
    }

    onAccept(values.recordingName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <Modal
      open={visible}
      title={t("recordingOptions")}
      onCancel={onCancel}
      centered
      onOk={() => {
        void onFormFinish();
      }}
      forceRender
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("recordingName")}
          name="recordingName"
          rules={[{ required: true }]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="tosAccepted"
          valuePropName="checked"
          wrapperCol={{ span: 24 }}
        >
          <Checkbox>
            {t("acceptRecordingTerms")}
            {/* <a href={t("recordingPrivacyUrl")} target="_blank" rel="noreferrer">
              {t("recordingTerms")}
            </a> */}
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
