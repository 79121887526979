import { tokenIsExpired, tokenIsAboutToExpire } from "@/helpers/tokenHelper";
import { RootState, store } from "@/redux/store";
import { useSelector } from "react-redux";
import { RequestConfig, getRenewedToken } from "@/services/erpService";
import { setToken } from "@/redux/slices/connectionSettingsSlice";
import { navigateTo } from "@/services/navigateService";

export const useJwtToken = () => {
  const { token } = useSelector((state: RootState) => state.connectionSettings);

  return {
    token,
    isAuthenticated: tokenIsExpired(token) !== null && !tokenIsExpired(token),
    tokenIsExpired: tokenIsExpired(token),
    tokenIsAboutToExpire: tokenIsAboutToExpire(token),
  };
};

// TODO: Remove this method once we use always the useErp hook
export const renewTokenIfNeeded = async (
  requestConfig: RequestConfig | undefined = {},
) => {
  const state = store.getState();
  const { token } = state.connectionSettings;

  if (token && tokenIsAboutToExpire(token)) {
    const refreshedToken = await getRenewedToken({ token }, requestConfig);
    store.dispatch(setToken(refreshedToken));
    return refreshedToken;
  }
  return token;
};

// TODO: Remove this method once we use always the useErp hook
export const redirectIfInvalidToken = () => {
  const state = store.getState();
  const { token } = state.connectionSettings;

  if (token && tokenIsExpired(token)) {
    navigateTo("login");
    return true;
  }
  return false;
};
