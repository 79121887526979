export default {
  welcome: "Bienvenido",
  sessionExpired: "La sesión ha expirado. Por favor, vuelve a iniciar sesión.",
  inputUsername: "Introduce el nombre de usuario",
  inputPassword: "Introduce la contraseña",
  username: "Usuario",
  password: "Contraseña",
  database: "Base de datos",
  loading: "Cargando...",
  login: "Inicia sesión",
  preferences: "Preferencias",
  confirmLangRefresh:
    "Para actualizar el idioma, es necesario recargar la página. Quieres recargarla ahora?",
  logout: "Cerrar sesión",
  default: "Por defecto",
  addCustomHost: "Añadir host personalizado",
  editCustomHost: "Editar host personalizado",
  removeCustomHost: "Eliminar host personalizado",
  hostName: "Nombre del host",
  host: "Host",
  errorHost: "El host ya existe",
  startRecording: "Empezar grabación",
  stopRecording: "Parar grabación",
  recordingInProgress: "Grabación en curso...",
  recordingOptions: "Opciones de grabación",
  recordingName: "Nombre de la grabación",
  acceptRecordingTerms:
    "Acepto grabar mi sesión para poder depurar un problema",
  recordingTerms: "política de privacidad de la grabación",
  recordingPrivacyUrl: "https://www.google.com/policies/privacy/",
  recordingMustAcceptTos: "Tienes que aceptar la",
  updateAvailable: "Actualización disponible",
  updateAvailableDescription: "Hay una nueva versión de la aplicación web.",
  updateButton: "Actualizar",
  tabsPendingSave:
    "Si tienes pestañas con cambios sin guardar, éstos se van a perder. Quieres continuar de todas formas para actualizar la versión?",
  discardAndUpdate: "Descartar cambios y actualizar",
  ignore: "Ignorar",
};
