import { MenuItem, MenuItemUi } from "@/models/menuItem";

// A recursion method for finding the item
// inside the items nested array
export const findDeepMenuItem = (
  id: string,
  items: MenuItemUi[],
): MenuItemUi | undefined => {
  for (const item of items || []) {
    // eslint-disable-next-line eqeqeq
    if (getChildestId(item.id) == getChildestId(id)) return item;

    if (item.children) {
      const _item = findDeepMenuItem(id, item.children);
      if (_item) return _item;
    }
  }
};

// A recursion method for finding the item inside nested array
// and updating that object with the passed one.
// Returns the updated nested array
export const updateDeepMenuItem = (
  menuItemUi: MenuItemUi,
  items: MenuItemUi[],
): MenuItemUi[] => {
  return items.map((item: MenuItemUi) => {
    return item.id === menuItemUi.id
      ? menuItemUi
      : {
          ...item,
          children: item.children
            ? updateDeepMenuItem(menuItemUi, item.children)
            : undefined,
        };
  });
};

export const updateDeepMenuItems = (
  menuItemUis: MenuItemUi[],
  items: MenuItemUi[],
): MenuItemUi[] => {
  let updatedMenuItems = [...items];
  menuItemUis.forEach((menuItemUi: MenuItemUi) => {
    updatedMenuItems = updateDeepMenuItem(menuItemUi, updatedMenuItems);
  });
  return updatedMenuItems;
};

// In order to get the tree menu simplified, we set item id's composed with their parents id
// and splitted by a dash.
// e.g:
// A submenu item with id 84, who's child of 17, and being item with id 17 at the same time child
// of 16, will result in a compound id for submenu item 84 of "16-17-84".
// Here, we retreive the childest id, so, in the previous example, this will return "84".
export const getChildestId = (compoundId: string): string => {
  return compoundId.includes("-")
    ? compoundId.split("-")[compoundId.split("-").length - 1]
    : compoundId;
};

export const itemHasChilds = (menuItem: MenuItemUi): boolean => {
  return (menuItem.child_id && menuItem.child_id.length > 0)!;
};

export const getCompoundId = ({
  parentId,
  childId,
}: {
  parentId?: string;
  childId: string;
}): string => {
  return parentId ? `${parentId}-${childId}` : childId;
};

export const getParentIdForDomain = (domain: any): number | boolean => {
  return domain.find((item: any) => item[0] === "parent_id")[2];
};

export const getParentItemsWithUpdatedChild = ({
  itemsData,
  menuItems,
}: {
  itemsData: MenuItem;
  menuItems: MenuItemUi[];
}) => {
  const itemsGroupedByParentId = (itemsData as any).reduce(
    (
      acc: Record<string, any[]>,
      item: { parent_id: Array<{ toString: () => any }> },
    ) => {
      const parentId = item.parent_id[0].toString();
      if (!acc[parentId]) {
        acc[parentId] = [];
      }
      acc[parentId].push(item);
      return acc;
    },
    {},
  );

  let updatedMenuItems = [...menuItems];

  Object.keys(itemsGroupedByParentId).forEach((key) => {
    const parentId = key;
    const items = itemsGroupedByParentId[key];
    const parentItem = findDeepMenuItem(
      parentId,
      updatedMenuItems,
    ) as MenuItemUi;

    const children = items.map((item: MenuItem) => {
      const { id, parent_id, child_id = [], ...rest } = item;

      const compoundId = getCompoundId({
        parentId: parentItem.id,
        childId: id.toString(),
      });

      return {
        id: compoundId,
        child_id: child_id.map((c) =>
          getCompoundId({
            parentId: compoundId,
            childId: c.toString(),
          }),
        ),
        ...rest,
      };
    });

    updatedMenuItems = updateDeepMenuItem(
      {
        ...parentItem,
        children,
      },
      updatedMenuItems,
    );
  });

  return updatedMenuItems;
};

export default {
  findDeepMenuItem,
  updateDeepMenuItem,
  getChildestId,
  itemHasChilds,
  getCompoundId,
  updateDeepMenuItems,
};
