import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useJwtToken } from "@/hooks/useJwtToken";

export const PrivateComponent = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useJwtToken();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { state: { from: location } });
    }
  }, [navigate, location, isAuthenticated]);

  return <>{isAuthenticated && children}</>;
};
