import { useCallback } from "react";
import { PromiseType, useErp } from "./erp/useErp";
import {
  getAvailableDatabases,
  getLoginMessage,
  getServerVersion,
} from "@/services/erpService";
import { useRemoteConfig } from "@/redux/slices/remoteConfigSlice";
import { TimeoutError } from "@/helpers/error";

type PreLoginInfo = {
  databases: PromiseType<ReturnType<typeof getAvailableDatabases>>;
  loginMessage: PromiseType<ReturnType<typeof getLoginMessage>>;
  serverVersion: PromiseType<ReturnType<typeof getServerVersion>>;
};

export function usePreLoginInfo(): [() => Promise<PreLoginInfo>, () => void] {
  const { loginTimeout } = useRemoteConfig();

  const [fetchDatabases, cancelDatabaseRequest] = useErp(
    "getAvailableDatabases",
    { auth: false },
  );
  const [fetchLoginMessage, cancelMessageRequest] = useErp("getLoginMessage", {
    auth: false,
  });
  const [fetchServerVersion, cancelServerVersionRequest] = useErp(
    "getServerVersion",
    { auth: false },
  );

  const fetchData = useCallback(async (): Promise<PreLoginInfo> => {
    const timeoutPromise = new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        reject(new TimeoutError("Request timed out"));
      }, loginTimeout);
    });

    const result = await Promise.race([
      Promise.all([
        fetchDatabases(),
        fetchLoginMessage(),
        fetchServerVersion(),
      ]),
      timeoutPromise, // Wait for the first one to resolve or reject
    ]);

    if (!Array.isArray(result)) {
      // Handle the timeout case (technically, this block won't be reached because timeoutPromise rejects, not resolves)
      throw new TimeoutError("Request timed out");
    }

    const [databases, loginMessage, serverVersion] = result;

    return {
      databases,
      loginMessage,
      serverVersion,
    };
  }, [fetchDatabases, fetchLoginMessage, fetchServerVersion, loginTimeout]);

  const cancelRequest = useCallback(() => {
    cancelDatabaseRequest();
    cancelMessageRequest();
    cancelServerVersionRequest();
  }, [cancelDatabaseRequest, cancelMessageRequest, cancelServerVersionRequest]);

  return [fetchData, cancelRequest];
}
