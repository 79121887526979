import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useErpState } from "./erp/useErp";
import { setFeatureState } from "@/redux/slices/erpFeaturesSlice";
import {
  CheckFeaturesResponse,
  FeatureConfigResponse,
} from "@/models/erpFeature";
import { ErpAllFeatureKeys } from "@gisce/react-ooui";

export const useFetchErpFeatures = (): { loading: boolean } => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [erpFeaturesResult, fetchErpFeatures, cancelRequest] = useErpState(
    "checkForFeatures",
    {
      auth: false,
      initialState: {
        loading: true,
      },
    },
  );

  const { data, error } = erpFeaturesResult;

  useEffect(() => {
    fetchErpFeatures(ErpAllFeatureKeys);
    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      // If the endpoint does not support feature checking, we'll ignore it
      console.error({ fetchERpFeaturesError: error });
      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((featureKey) => {
        const key = featureKey as keyof CheckFeaturesResponse;
        const featureResponse: FeatureConfigResponse = data[key] || {};
        const { value } = featureResponse;
        dispatch(
          setFeatureState({
            feature: key,
            isEnabled: value || false,
          }),
        );
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { loading };
};
