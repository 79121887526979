import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getClient } from "@/services/powerpClient";

const API_SUFFIX = "/api";

export type CustomHost = {
  name: string;
  url: string;
};

interface ConnectionSettingsState {
  token: string | null;
  currentHost: string;
  defaultHost: string;
  customHosts: CustomHost[];
  database: string | null;
}

const initialState: ConnectionSettingsState = {
  token: null,
  currentHost: import.meta.env.VITE_HOST || API_SUFFIX,
  defaultHost: import.meta.env.VITE_HOST || API_SUFFIX,
  customHosts: [],
  database: null,
};

export const connectionSettingsSlice = createSlice({
  name: "connectionSettings",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      if (action.payload) {
        getClient().setToken(action.payload);
      }
    },
    setCurrentHost: (state, action: PayloadAction<string>) => {
      state.currentHost = action.payload;
      getClient().setHost(action.payload);
    },
    addCustomHost: (
      state,
      action: PayloadAction<{ url: string; name: string }>,
    ) => {
      state.customHosts.push(action.payload);
    },
    removeCustomHost: (state, action: PayloadAction<string>) => {
      state.customHosts = state.customHosts.filter(
        (host) => host.url !== action.payload,
      );
    },
    modifyCustomHost: (
      state,
      action: PayloadAction<{ oldHost: CustomHost; newHost: CustomHost }>,
    ) => {
      const { oldHost, newHost } = action.payload;
      state.customHosts = state.customHosts.map((customHost) => {
        if (customHost.url === oldHost.url) {
          return newHost;
        }
        return customHost;
      });
    },
    setDatabase: (state, action: PayloadAction<string | null>) => {
      state.database = action.payload;
      if (action.payload) {
        getClient().setDatabase(action.payload);
      }
    },
  },
});

export const {
  setToken,
  setCurrentHost,
  addCustomHost,
  removeCustomHost,
  modifyCustomHost,
  setDatabase,
} = connectionSettingsSlice.actions;

export const useConnectionSettings = () => {
  return useSelector((state: RootState) => state.connectionSettings);
};

export default connectionSettingsSlice.reducer;
