import React, { useCallback } from "react";

import { LoadingOutlined } from "@ant-design/icons";

import Menu from "@/components/menu/menu";
import { useMenuContext } from "@/components/menu/menuContext";
import MenuEntry from "@/components/menu/menuEntry";
import SubMenuEntry from "@/components/menu/subMenuEntry";
import { MenuItemUi } from "@/models/menuItem";
import { Alert, theme } from "antd";

import MenuHelper from "@/helpers/menuHelper";
import { iconMapper } from "@gisce/react-ooui";
import { useLocale } from "@gisce/react-formiga-components";
import { getErrorMessage } from "@/helpers/error";

const { useToken } = theme;

type Props = {
  onItemClicked: (item: MenuItemUi) => void;
};

function TreeViewMenu(props: Props): React.ReactElement {
  const { onItemClicked } = props;
  const { menuItems, error, loading } = useMenuContext();
  const { token } = useToken();
  const color = token.colorPrimaryActive;
  const { t } = useLocale();

  const onMenuEntryClicked = useCallback(
    (id: string) => {
      const menuItemClicked = MenuHelper.findDeepMenuItem(id, menuItems);
      onItemClicked(menuItemClicked!);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuItems],
  );

  const onSubMenuTitleClicked = useCallback(
    (id: string) => {
      const menuItemClicked = MenuHelper.findDeepMenuItem(id, menuItems);
      if (menuItemClicked?.action !== false) {
        onItemClicked(menuItemClicked!);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuItems],
  );

  const getMenuEntry: any = (item: MenuItemUi) => {
    const { id } = item;
    const Icon: any = iconMapper(item.icon, {
      className: `text-lg`,
      style: { color },
    });
    const icon = Icon && <Icon />;
    if (!icon) {
      // console.debug(`Icon not defined for ${item.icon}`);
    }

    // In case the submenu item has children already loaded from the API
    if (MenuHelper.itemHasChilds(item) && item.children) {
      const childrenComponents = item.children?.map((subItem) =>
        getMenuEntry(subItem),
      );

      return (
        <SubMenuEntry
          key={id}
          id={id}
          title={item.name}
          icon={icon}
          {...subMenuProps}
          color={color}
        >
          {childrenComponents}
        </SubMenuEntry>
      );
    }
    // In case it's an item without childs
    else if (!MenuHelper.itemHasChilds(item)) {
      return (
        <MenuEntry
          key={id}
          id={id}
          title={item.name}
          icon={icon}
          {...menuItemProps}
          color={color}
        />
      );
    }
    // In case it's a submenu item that hasn't got loaded children yet
    else {
      return (
        <SubMenuEntry
          key={id}
          id={id}
          title={item.name}
          icon={icon}
          {...subMenuProps}
          color={color}
        >
          <MenuEntry
            id={"loading-" + item.id}
            title={t("loading") || ""}
            icon={<LoadingOutlined className={`text-lg`} style={{ color }} />}
            {...menuItemProps}
            color={color}
          />
        </SubMenuEntry>
      );
    }
  };

  const subMenuProps = {
    onClick: onSubMenuTitleClicked,
    color,
  };

  const menuItemProps = {
    onClick: onMenuEntryClicked,
    color,
  };

  if (error) {
    return (
      <Alert
        className="mb-8"
        message={getErrorMessage(error)}
        type="error"
        showIcon
      />
    );
  }

  if (loading) {
    return (
      <div className="pt-2 pb-20 pl-2 pr-5">
        <LoadingOutlined
          className="text-lg"
          style={{ color: token.colorPrimaryBorder }}
        />
        <span className="pl-2">{t("loading")}</span>
      </div>
    );
  }

  return <Menu>{menuItems.map((item) => getMenuEntry(item))}</Menu>;
}

export default TreeViewMenu;
