import { useLogin } from "@/hooks/useLogin";
import { SigninRedirectArgs, User } from "oidc-client-ts";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useOpenIdUserManager } from "@/hooks/useOpenIdUserManager";
import { useLogout } from "@/hooks/useLogout";

export interface Location {
  search: string;
  hash: string;
}

export interface AuthProviderProps {}

export interface AuthContextProps {
  signInOpenId: (args?: SigninRedirectArgs) => Promise<void>;
  signOutOpenId: () => Promise<void>;
  fetchData?: () => Promise<any>;
  loading: boolean;
  openIdLoading: boolean;
  formIsLoading: boolean;
  form: any;
  submitForm: (values: any) => Promise<void>;
  formError: string | null;
  serverError: string | null;
  loginMessage: string;
  serverVersion: string;
  databaseSelected: string;
  setDatabaseSelected: (database: string) => void;
  databases: string[];
  onLoginWithOpenId: () => Promise<void>;
  mount: () => void;
  logout: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextProps | undefined>(
  undefined,
);

export const hasCodeInUrl = (location: Location): boolean => {
  const searchParams = new URLSearchParams(location.search);
  const hashParams = new URLSearchParams(location.hash.replace("#", "?"));

  return Boolean(
    searchParams.get("code") ||
      searchParams.get("id_token") ||
      searchParams.get("session_state") ||
      hashParams.get("code") ||
      hashParams.get("id_token") ||
      hashParams.get("session_state"),
  );
};

export const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({
  children,
}) => {
  const location = window.location;
  const { getUserManager, openidAuthorityUrl, openidFullLogout } =
    useOpenIdUserManager();
  const isMountedRef = useRef<boolean>(false);
  const logout = useLogout();

  const {
    fetchData,
    loading,
    openIdLoading,
    formIsLoading,
    form,
    submitForm,
    formError,
    serverError,
    loginMessage,
    serverVersion,
    databaseSelected,
    setDatabaseSelected,
    databases,
    onLoginWithOpenId,
    setOpenIdLoading,
    mount,
  } = useLogin(
    useMemo(() => {
      return {
        signInOpenId: async (): Promise<void> => {
          await getUserManager().signinRedirect();
        },
        signOutOpenId: async (): Promise<void> => {
          await getUserManager().removeUser();
        },
      };
    }, [getUserManager]),
  );

  const onSignIn = useCallback(
    async (user: User | null): Promise<void> => {
      const { databases } = (await fetchData?.())!;
      await submitForm?.({
        database: databases[0],
        user: "oidc_access_token",
        password: user?.access_token,
      });
      setOpenIdLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [databases],
  );

  useEffect(() => {
    let isMounted = true;
    isMountedRef.current = true;
    void (async () => {
      const user = await getUserManager().getUser();
      // isMountedRef cannot be used here as its value is updated by next useEffect.
      // We intend to keep context of current useEffect.
      if (isMounted && (!user || user.expired)) {
        // If the user is returning back from the OIDC provider, get and set the user data.
        if (hasCodeInUrl(location)) {
          const user = (await getUserManager().signinCallback()) || null;
          void onSignIn?.(user);
        }
      }
    })();
    return () => {
      isMounted = false;
      isMountedRef.current = false;
    };
  }, [getUserManager, location, onSignIn]);

  const value = useMemo(() => {
    return {
      signInOpenId: async (): Promise<void> => {
        await getUserManager().signinRedirect();
      },
      signOutOpenId: async (): Promise<void> => {
        await getUserManager().removeUser();

        if (openidAuthorityUrl && openidFullLogout) {
          await getUserManager().signoutRedirect();
        }
      },
      fetchData,
      loading,
      openIdLoading,
      formIsLoading,
      form,
      submitForm,
      formError,
      serverError,
      loginMessage,
      serverVersion,
      databaseSelected,
      setDatabaseSelected,
      databases,
      onLoginWithOpenId,
      mount,
      logout,
    };
  }, [
    fetchData,
    loading,
    openIdLoading,
    formIsLoading,
    form,
    submitForm,
    formError,
    serverError,
    loginMessage,
    serverVersion,
    databaseSelected,
    setDatabaseSelected,
    databases,
    onLoginWithOpenId,
    mount,
    logout,
    getUserManager,
    openidAuthorityUrl,
    openidFullLogout,
  ]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextProps => {
  const context = useContext<AuthContextProps | undefined>(AuthContext);

  if (!context) {
    throw new Error(
      "AuthProvider context is undefined, please verify you are calling useAuth() as child of a <AuthProvider> component.",
    );
  }

  return context;
};
