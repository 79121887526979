import Main from "@/components/views/Main/Main";
import Login from "@/components/views/Login/Login";
import { AuthProvider } from "@/context/auth/AuthContext";
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useRemoteConfig } from "@/redux/slices/remoteConfigSlice";
import { PublicComponent } from "@/router/PublicComponent";
import { PrivateComponent } from "@/router/PrivateComponent";
import usePowerpSetup from "@/hooks/usePowerpSetup";
import { useInitialDataFetch } from "@/hooks/useInitialDataFetch";
import { useFeatures } from "@/hooks/useErpFeatures";
import { ConfigContextProvider } from "@gisce/react-ooui";
import { strings, useLang } from "@/hooks/useLang";
import { useUserId } from "@/hooks/useUserId";
import { useUserInfo } from "@/redux/slices/userInfoSlice";
import { Theme } from "./Theme";
import { useUpdateDayjsLocale } from "@/hooks/useUpdateDayjsLocale";

const App = () => {
  const { title } = useRemoteConfig();

  usePowerpSetup();
  const erpFeatures = useFeatures();

  const { lang } = useLang();
  useUpdateDayjsLocale(lang);

  const userId = useUserId();
  const { context } = useUserInfo();

  useEffect(() => {
    document.title = title;
  }, [title]);

  const { loading } = useInitialDataFetch();

  if (loading) {
    return null;
  }

  return (
    <Theme>
      <ConfigContextProvider
        erpFeatures={erpFeatures}
        locale={lang}
        localizedStrings={strings}
        rootContext={context}
        globalValues={{
          uid: userId,
        }}
        devMode={import.meta.env.DEV}
      >
        <AuthProvider>
          <Routes>
            <Route
              path="/login"
              element={
                <PublicComponent>
                  <Login />
                </PublicComponent>
              }
            />
            <Route
              path="/"
              element={
                <PrivateComponent>
                  <Main />
                </PrivateComponent>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </AuthProvider>
      </ConfigContextProvider>
    </Theme>
  );
};

export default App;
