/* eslint-disable @typescript-eslint/restrict-template-expressions */
import TrackService from "@/services/trackService";
import {
  LogoutOutlined,
  MenuOutlined,
  SettingFilled,
  StopOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Layout, Popover, Row, Space, Typography } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useEffect, useState } from "react";
import { TrackingModal } from "../views/TrackingModal";
import { RecordingDot } from "./RecordingDot";
import { useTheme } from "@/hooks/useTheme";
import { useRemoteConfig } from "@/redux/slices/remoteConfigSlice";
import { useUserInfo } from "@/redux/slices/userInfoSlice";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { FavouriteButton } from "@gisce/react-ooui";
import { useErp } from "@/hooks/erp/useErp";
import { PACKAGE_VERSION } from "@/config/constants";
import { useLocale } from "@gisce/react-formiga-components";

const { Header: AntHeader } = Layout;
const { Text } = Typography;

type Props = {
  onToggleMenu: () => void;
  onLogout: () => void;
  onPreferencesRequested: () => void;
  tabSelector: any;
  sidebarCollapsed: boolean;
};

function Header(props: Props): React.ReactElement {
  const {
    onToggleMenu,
    onLogout,
    onPreferencesRequested,
    tabSelector,
    sidebarCollapsed,
  } = props;
  const { t } = useLocale();
  const theme = useTheme();
  const { title, openreplayApiKey } = useRemoteConfig();
  const { user } = useUserInfo();
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [recordingOptsVisible, setRecordingOptsVisible] =
    useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(
    TrackService.getInstance().isRecording,
  );
  const { context } = useUserInfo();

  const [addFavourite, cancelAddFavouriteRequest] = useErp("addFavourite");
  const [getShortcuts, cancelGetShortcutsRequest] = useErp("getShortcuts");
  const [isShortcutFavorite, cancelIsShortcutFavoriteRequest] =
    useErp("isShortcutFavorite");
  const [removeFavourite, cancelRemoveFavouriteRequest] =
    useErp("removeFavourite");

  useEffect(() => {
    return () => {
      cancelAddFavouriteRequest();
      cancelGetShortcutsRequest();
      cancelIsShortcutFavoriteRequest();
      cancelRemoveFavouriteRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startRecording = useCallback(
    (recordingName: string) => {
      if (!openreplayApiKey || !user) {
        return;
      }
      setRecordingOptsVisible(false);
      TrackService.getInstance().start({
        recordingName,
        apiKey: openreplayApiKey,
        username: user.login,
      });
      setIsRecording(true);
    },
    [setIsRecording, openreplayApiKey, user],
  );

  const handleRecordClick = useCallback(() => {
    setPopoverVisible(false);

    if (isRecording) {
      TrackService.getInstance().stop();
      setIsRecording(!isRecording);
    } else {
      setRecordingOptsVisible(true);
    }
  }, [isRecording]);

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const popoverContent = (
    <>
      <Row>
        <Button
          className="text-left"
          icon={<SettingFilled />}
          type="link"
          onClick={() => {
            onPreferencesRequested();
            setPopoverVisible(false);
          }}
          block
        >
          {t("preferences")}
        </Button>
      </Row>
      <Space />
      {openreplayApiKey && (
        <>
          <Row>
            <Button
              className="text-left"
              icon={isRecording ? <StopOutlined /> : <VideoCameraOutlined />}
              type="link"
              onClick={handleRecordClick}
              block
            >
              {isRecording ? t("stopRecording") : t("startRecording")}
            </Button>
          </Row>
          <Space />
        </>
      )}
      <Row>
        <Button
          className="text-left"
          icon={<LogoutOutlined />}
          type="link"
          onClick={() => {
            onLogout();
            setPopoverVisible(false);
          }}
          block
        >
          {t("logout")}
        </Button>
      </Row>
      <Text type="secondary" style={{ fontSize: 10 }} className="w-full pl-3">
        {`Client: ${PACKAGE_VERSION}`}
      </Text>
    </>
  );

  return (
    <ErrorBoundary>
      <AntHeader
        className="fixed z-10 flex items-center w-full p-0"
        style={{
          backgroundColor: theme.colorPrimaryActive,
          height: 64,
        }}
      >
        <div className={"flex-none mr-5"}>
          <div className={"flex items-center pl-5"}>
            <a title="Toggle menu" onClick={onToggleMenu} className="mt-2">
              <MenuOutlined className="text-2xl text-white" />
            </a>
            <Title
              style={{
                color: "white",
                userSelect: "none",
                marginBottom: "0.25rem",
                marginLeft: "1.25rem",
              }}
              level={3}
            >
              {title}
            </Title>
            <div
              style={{
                transition: "width 0.3s",
                width: sidebarCollapsed ? "0px" : "200px",
              }}
            />
          </div>
        </div>
        <div
          className="items-center flex-grow"
          style={{ marginTop: 24, overflow: "hidden" }}
        >
          {tabSelector}
        </div>

        <div className="flex-none mr-5 w-30">
          <div className="flex items-center">
            <FavouriteButton
              onCheckIsFavourite={async (shortcut: any) => {
                return await isShortcutFavorite({
                  ...shortcut,
                  context,
                });
              }}
              onRetrieveShortcuts={async () => {
                return await getShortcuts({
                  context,
                });
              }}
              onAddFavourite={async (shortcut: any) => {
                return await addFavourite({
                  ...shortcut,
                  context,
                });
              }}
              onRemoveFavourite={async (shortcutId: number) => {
                return await removeFavourite({
                  shortcut_id: shortcutId,
                  context,
                });
              }}
              onDropdownClosed={cancelGetShortcutsRequest}
            />
          </div>
        </div>

        <div className="flex-none w-10 mr-5">
          <div className="flex items-center">
            <Popover
              placement="bottomRight"
              content={popoverContent}
              trigger="click"
              open={popoverVisible}
              onOpenChange={handleVisibleChange}
            >
              <Avatar
                className="bg-red-500 cursor-pointer select-none algin-middle"
                size="large"
                gap={4}
              >
                {user?.login.charAt(0).toUpperCase()}
              </Avatar>
            </Popover>
          </div>
          {isRecording && <RecordingDot style={{ position: "absolute" }} />}
        </div>
        <TrackingModal
          visible={recordingOptsVisible}
          onAccept={startRecording}
          onCancel={() => {
            setRecordingOptsVisible(false);
          }}
        />
      </AntHeader>
    </ErrorBoundary>
  );
}

export default Header;
