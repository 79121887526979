import { useCallback } from "react";
import { setUser } from "@/redux/slices/userInfoSlice";
import { setDatabase, setToken } from "@/redux/slices/connectionSettingsSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOpenIdUserManager } from "./useOpenIdUserManager";

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getUserManager } = useOpenIdUserManager();

  return useCallback(async () => {
    dispatch(setUser(null));
    dispatch(setDatabase(null));
    dispatch(setToken(null));
    await getUserManager().removeUser();
    navigate("/login");
  }, [dispatch, navigate, getUserManager]);
};
