import { useUpdateCheck } from "@/hooks/versionChecker/useUpdateCheck";
import { useLocale } from "@gisce/react-formiga-components";
import { Button, notification, Space } from "antd";
import { useCallback, useMemo, useRef, useState } from "react";
import { iconMapper, showConfirmDialog } from "@gisce/react-ooui";
import { ReloadOutlined } from "@ant-design/icons";

export const UpdateNotification = () => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useLocale();
  const notificationAlreadyShown = useRef(false);
  const [ignoreNotification, setIgnoreNotification] = useState(false);
  const IconBellOff: React.ElementType = useMemo(
    () =>
      iconMapper("BellOff", {
        style: {
          color: "#FF4D4F",
          fontSize: 14,
          marginTop: 3,
        },
      }),
    [],
  );

  const openNotification = useCallback(() => {
    if (notificationAlreadyShown.current || ignoreNotification) return;
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          danger
          type="default"
          icon={<IconBellOff />}
          size="middle"
          onClick={() => {
            setIgnoreNotification(true);
            api.destroy(key);
          }}
        >
          {t("ignore")}
        </Button>
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          size="middle"
          onClick={() => {
            api.destroy(key);
            showConfirmDialog({
              okText: t("discardAndUpdate"),
              confirmMessage: t("tabsPendingSave"),
              onOk: () => {
                window.location.reload();
              },
              t,
            });
          }}
        >
          {t("updateButton")}
        </Button>
      </Space>
    );
    notificationAlreadyShown.current = true;
    api.info({
      message: t("updateAvailable"),
      description: t("updateAvailableDescription"),
      duration: null,
      btn,
      key,
      onClose: () => {
        notificationAlreadyShown.current = false;
      },
    });
  }, [IconBellOff, api, ignoreNotification, t]);

  useUpdateCheck({
    disabled: ignoreNotification,
    onUpdateAvailable: openNotification,
  });

  return contextHolder;
};

export const UpdateNotificationContent = () => {};
