import { One2manyItem, One2manyValue } from "@gisce/react-ooui";

//
// Since the format we have to send when we'll write an object (create or update)
// Is different than the one that we get when reading, we must convert certain fields
//

export const prepareWriteValues = ({
  values,
  fields,
  evalDomain = false,
}: {
  values: any;
  fields: any;
  evalDomain?: boolean;
}): any => {
  if (!fields) {
    return values;
  }

  const processedTouchedValues: any = {};

  Object.entries(values)
    .filter(([, value]) => value !== undefined)
    .forEach((item: any) => {
      const [name, value] = item;

      if (
        fields[name] &&
        fields[name].type === "many2one" &&
        Array.isArray(value)
      ) {
        processedTouchedValues[name] = value[0] || null;
        return;
      }

      if (
        fields[name] &&
        (fields[name].type === "one2many" || fields[name].type === "many2many")
      ) {
        const o2m_value: One2manyValue = value as unknown as One2manyValue;

        const items: One2manyItem[] = o2m_value.items || [];
        const o2m_fields = o2m_value.fields;

        const itemsToUpdate =
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
          evalDomain === false
            ? items.filter((item) => item.operation !== "original")
            : items;

        if (itemsToUpdate.length === 0) {
          processedTouchedValues[name] = undefined;
          return;
        }

        let m2m_links = [];
        let other_items = [];
        let finalOperations: any[] = [];

        if (fields[name].type === "many2many") {
          if (
            itemsToUpdate.some(
              (item) =>
                item.operation === "pendingLink" ||
                item.operation === "pendingRemove",
            )
          ) {
            m2m_links = items.filter(
              (item) => item.operation !== "pendingRemove",
            );

            const idsToLink = m2m_links.map((item) => {
              return item.id;
            });

            if (idsToLink.length > 0) {
              finalOperations = [[6, 0, idsToLink]];
            }
          }

          other_items = itemsToUpdate.filter(
            (item) => item.operation !== "pendingLink",
          );
        } else {
          other_items = itemsToUpdate;
        }

        processedTouchedValues[name] = [
          ...finalOperations,
          ...other_items.map((item) => {
            return get2ManyPayload({
              item,
              type: fields[name].type,
              fields: o2m_fields,
            });
          }),
        ];

        return;
      }

      if (
        fields[name] &&
        fields[name].type === "reference" &&
        value &&
        typeof value === "string" &&
        value.includes(",") &&
        value.split(",").length === 2 &&
        value.split(",")[1] === "0"
      ) {
        processedTouchedValues[name] = false;
        return;
      }

      processedTouchedValues[name] = value;
    });
  return processedTouchedValues;
};

const get2ManyPayload = ({
  item,
  type,
  fields,
}: {
  item: One2manyItem;
  type: "one2many" | "many2many";
  fields: any;
}) => {
  switch (item.operation) {
    case "pendingCreate": {
      const values = { ...item.values };
      delete values.id;
      return [0, 0, prepareWriteValues({ values, fields })];
    }

    case "pendingRemove": {
      if (type === "many2many") {
        return [3, item.id];
      } else {
        return [2, item.id];
      }
    }
    case "pendingLink": {
      return [4, item.id];
    }
    case "original":
    case "pendingUpdate":
    default: {
      const values = { ...item.values };
      delete values.id;
      return [1, item.id, prepareWriteValues({ values, fields })];
    }
  }
};

export const prepareReadValues = ({
  values,
  fields,
}: {
  values: any;
  fields: any;
}): any => {
  const processedValues: any = {};

  if (!fields) {
    return values;
  }

  Object.entries(values)
    .filter(([, value]) => value !== undefined)
    .forEach((item: any) => {
      const [name, value] = item;

      if (
        fields[name] &&
        (fields[name].type === "many2many" ||
          fields[name].type === "one2many") &&
        Array.isArray(value) &&
        value.length > 0 &&
        // eslint-disable-next-line eqeqeq
        typeof value[0] == "number"
      ) {
        processedValues[name] = {};
        processedValues[name].items = value.map((itemId: string) => {
          return {
            operation: "original",
            id: itemId,
          };
        });
        return;
      }

      processedValues[name] = value;
    });
  return processedValues;
};

export const getRelationFieldsWithContext = (viewFields: any) => {
  return Object.keys(viewFields || {}).filter((fieldName) => {
    const relationExist =
      viewFields[fieldName].relation &&
      viewFields[fieldName].relation.length > 0;

    if (!relationExist) {
      return false;
    }

    const context = viewFields[fieldName].context;
    if (!context) {
      return false;
    }
    // check if context is a string
    const contextIsString = typeof context === "string";
    // check if context is an object
    const contextIsObject = typeof context === "object";

    if (contextIsString) {
      return context.trim().length > 0 && context.trim() !== "{}";
    }

    if (contextIsObject) {
      return Object.keys(context).length > 0;
    }

    return false;
  });
};

export function flattenById(items: any[]): any[] {
  const map = new Map<number, any>();

  for (const group of items) {
    for (const item of group) {
      const existingItem = map.get(item.id);
      if (existingItem) {
        map.set(item.id, {
          ...existingItem,
          ...item,
          ...Object.keys(item).reduce<any>((acc, key) => {
            if (Array.isArray(item[key])) {
              acc[key] = Array.isArray(existingItem[key])
                ? [...new Set(existingItem[key].concat(item[key]))]
                : item[key];
            }
            return acc;
          }, {}),
        });
      } else {
        map.set(item.id, { ...item });
      }
    }
  }

  return Array.from(map.values());
}
