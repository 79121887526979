import {
  flattenById,
  getRelationFieldsWithContext,
  prepareReadValues,
  prepareWriteValues,
} from "@/helpers/erpReadWriteHelper";
import {
  redirectIfInvalidToken,
  renewTokenIfNeeded,
} from "@/hooks/useJwtToken";
import { Model, Report } from "@gisce/powerp.js";
import {
  AttributeConditionParserOpts,
  CreateReportOpts,
  ModelFieldsGetOpts,
  ModelFieldsViewGetOpts,
} from "@gisce/powerp.js/dist/types";
import axios, { AxiosRequestConfig } from "axios";
import { DOMParser as DOM } from "xmldom";
import xpath from "xpath";
import { getClient } from "./powerpClient";
import { RemoteConfigState } from "@/redux/slices/remoteConfigSlice";
import {
  CreateObjectRequest,
  CreateReportRequest,
  DefaultGetRequest,
  ExecuteOnChangeRequest,
  GetReportRequest,
  ReadEvalUiObjectsRequest,
  ReadObjectsRequest,
  SearchRequest,
  UpdateObjectRequest,
  ViewData,
  ErpFeatureKeys,
} from "@gisce/react-ooui";
import { isFeatureEnabled } from "@/hooks/useErpFeatures";
import { CheckFeaturesResponse } from "@/models/erpFeature";

export type RequestConfig = AxiosRequestConfig;

const VIEW_VERSION = 2;

const extractActionAndId = (actionAndId: string) => {
  const [name, idString] = actionAndId.split(",");
  const id: number = parseInt(idString);
  return { name, id };
};

export const getViewsForAction = async (
  {
    action,
    context,
  }: {
    action: string;
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<ViewData> => {
  const {
    res_model: model,
    views: viewsArray,
    limit,
    domain,
    context: contextAction,
  } = await getActionData({ action, context }, requestConfig);

  const viewsMap = new Map<string, any>();

  await Promise.all(
    viewsArray?.map(async (item: any[]) => {
      const [id, type] = item;
      if (!id) {
        return;
      }
      const response = await getView(
        { model, id, type, context },
        requestConfig,
      );
      viewsMap.set(type, response);
    }),
  );

  return {
    views: viewsMap,
    limit,
    model,
    domain: domain || undefined,
    context: contextAction,
  };
};

export const getActionData = async (
  {
    action,
    context = {},
  }: {
    action: string;
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();

  const { name, id } = extractActionAndId(action);

  const actionTypeModel = new Model(name, client);
  return (
    await actionTypeModel.read(
      {
        ids: [id],
        context,
      },
      requestConfig,
    )
  )[0];
};

export const searchAllIds = async (
  {
    params,
    model,
    totalItems,
    context,
    order,
  }: {
    params: any;
    model: string;
    totalItems?: number;
    context?: any;
    order?: string | undefined;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const searchModel = new Model(model, client);
  const searchResults = await searchModel.search(
    {
      params,
      limit: totalItems,
      offset: 0,
      context,
      order,
    },
    requestConfig,
  );

  return searchResults;
};

export const searchForTree = async (
  {
    params,
    limit = 80,
    offset = 0,
    model,
    fields,
    context = {},
    attrs,
  }: {
    params: any;
    limit?: number;
    offset?: number;
    model: string;
    fields?: string[];
    context?: any;
    attrs?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const searchModel = new Model(model, client);
  const totalItems = searchModel.search({
    params,
    count: true,
    context,
  });

  const searchIds = await searchModel.search(
    {
      params,
      limit,
      offset,
      context,
    },
    requestConfig,
  );

  let results = [];
  let attrsEvaluated;

  if (searchIds.length > 0) {
    if (attrs) {
      [results, attrsEvaluated] = await searchModel.read_and_eval_ui(
        {
          ids: searchIds,
          fields: Object.keys(fields!),
          context,
          attrs,
        },
        requestConfig,
      );
    } else {
      results = await searchModel.read(
        {
          ids: searchIds,
          fields,
          context,
        },
        requestConfig,
      );
    }
  }

  if (fields && searchIds.length > 0) {
    const formattedResults = await prepareResults({
      model,
      ids: searchIds,
      results,
      viewFields: fields,
      parentContext: context,
    });

    return { results: formattedResults, totalItems, attrsEvaluated };
  }

  return { results, totalItems, attrsEvaluated };
};

export const searchCount = async (
  {
    params,
    model,
    context = {},
  }: {
    params: any;
    model: string;
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const searchModel = new Model(model, client);
  const totalItems = await searchModel.search(
    {
      params,
      count: true,
      context,
    },
    requestConfig,
  );

  return totalItems;
};

export const search = async (
  opts: SearchRequest & { fieldsToRetrieve?: string[] },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  const {
    params,
    limit,
    offset,
    model,
    fields,
    context,
    order,
    fieldsToRetrieve,
  } = opts;

  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const searchModel = new Model(model, client);

  const searchIds = await searchModel.search(
    {
      params,
      limit,
      offset,
      context,
      order,
    },
    requestConfig,
  );

  if (searchIds.length === 0) {
    return [];
  }

  let results = await searchModel.read(
    {
      ids: searchIds,
      fields: fieldsToRetrieve,
      context,
    },
    requestConfig,
  );

  if (searchIds.length > 0 && searchIds[0] !== -1 && order !== undefined) {
    results = searchIds.map((resultId: number) => {
      return results.find((result: any) => result.id === resultId);
    });
  }

  if (fields && searchIds.length > 0) {
    const formattedResults = await prepareResults({
      model,
      ids: searchIds,
      results,
      viewFields: fields,
      parentContext: context,
    });

    return formattedResults;
  }

  return results;
};

export const getView = async (
  {
    model,
    id,
    type,
    context,
  }: {
    model: string;
    id: number | null;
    type: string;
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const actionModel = new Model(model, client);

  const jsonAttrsFeatureEnabled = isFeatureEnabled(
    ErpFeatureKeys.FEATURE_VIEW_JSON_ATTRS,
  );

  const overrideMethodName = jsonAttrsFeatureEnabled
    ? "fields_view_get_json_attrs"
    : undefined;

  const payload: ModelFieldsViewGetOpts = {
    type,
    id,
    toolbar: true,
    context,
    version: VIEW_VERSION,
    overrideMethodName,
  };

  const viewInfo = await actionModel.fields_view_get(payload, requestConfig);
  const { view_id: viewId } = viewInfo;
  if (!viewId) {
    return viewInfo;
  }

  const title = await getViewString({ id: viewId, context }, requestConfig);

  return { ...viewInfo, title };
};

export const getViewString = async (
  {
    id,
    context,
  }: {
    id: number;
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<string | undefined> => {
  let viewString: string | undefined;
  try {
    await renewTokenIfNeeded(requestConfig);
    if (redirectIfInvalidToken()) {
      throw new Error("Invalid token");
    }
    const client = getClient();
    const viewModel = new Model("ir.ui.view", client);
    viewString = await viewModel.execute(
      {
        payload: id,
        action: "get_view_string",
        context,
      },
      requestConfig,
    );
  } catch (e) {
    // Do nothing.
  }
  return viewString;
};

export const getFields = async (
  {
    model,
    fields,
    context,
  }: {
    model: string;
    fields: string[];
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const actionModel = new Model(model, client);

  const payload: ModelFieldsGetOpts = {
    fields,
    context,
  };

  return await actionModel.fields_get(payload, requestConfig);
};

export const execute = async (
  {
    model,
    action,
    payload,
    context,
  }: {
    model: string;
    action: string;
    payload?: any;
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const modelObj = new Model(model, client);
  return await modelObj.execute(
    {
      action,
      payload,
      context,
    },
    requestConfig,
  );
};

export const executeWorkflow = async (
  {
    model,
    action,
    payload,
  }: {
    model: string;
    action: string;
    payload?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const modelObj = new Model(model, client);
  return await modelObj.executeWorkflow(
    {
      action,
      payload,
    },
    requestConfig,
  );
};

const applyNameGetMany2One = async (
  {
    fields,
    values,
  }: {
    fields: any;
    values: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  const convertedValues: any = {};

  const many2oneFields = Object.keys(fields).filter(
    (fieldName: any) => fields[fieldName].type === "many2one",
  );

  for (let i = 0; i < Object.keys(values).length; i++) {
    const fieldName = Object.keys(values)[i];

    if (
      many2oneFields.includes(fieldName) &&
      values[fieldName] &&
      !Array.isArray(values[fieldName])
    ) {
      const valueForMany2One = await execute(
        {
          action: "name_get",
          payload: [values[fieldName]],
          model: fields[fieldName].relation,
        },
        { ...requestConfig },
      );
      convertedValues[fieldName] = valueForMany2One[0] || false;
    } else {
      convertedValues[fieldName] = values[fieldName];
    }
  }

  return convertedValues;
};

export const readObjects = async (
  options: ReadObjectsRequest,
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  const {
    model,
    ids,
    fields: viewFields,
    context = {},
    fieldsToRetrieve: parmFieldsToRetrieve,
  } = options;

  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const userModel = new Model(model, client);

  let fieldsToRetrieve: string[] = parmFieldsToRetrieve || [];

  if (!parmFieldsToRetrieve && viewFields) {
    fieldsToRetrieve = Object.keys(viewFields);
  }

  const relationFieldsWithContext = getRelationFieldsWithContext(viewFields);

  const finalFields = fieldsToRetrieve.filter((field) => {
    return !relationFieldsWithContext.includes(field);
  });

  const results = await userModel.read(
    {
      ids,
      fields: finalFields,
      context,
    },
    requestConfig,
  );

  return await prepareResults({
    model,
    ids,
    results,
    viewFields,
    parentContext: context,
  });
};

export const parseCondition = async (
  options: AttributeConditionParserOpts,
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  const { values, context, condition } = options;

  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  return await client?.parseCondition(
    {
      values,
      context,
      condition,
    },
    requestConfig,
  );
};

export const readEvalUiObjects = async (
  options: ReadEvalUiObjectsRequest,
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  const { arch, model, ids, fields: viewFields, context = {}, attrs } = options;

  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const userModel = new Model(model, client);

  let fieldsToRetrieve;

  if (arch) {
    const doc = new DOM().parseFromString(arch);
    const nodes: Node[] = xpath.select("//*/@name", doc) as Node[];
    fieldsToRetrieve = nodes.map((node: any) => {
      return node.nodeValue;
    });
  }

  const object = await userModel.read_and_eval_ui(
    {
      ids,
      fields: fieldsToRetrieve,
      context,
      attrs,
    },
    requestConfig,
  );

  const [results, evaluatedAttrs] = object;

  const formattedResults = await prepareResults({
    model,
    ids,
    results,
    viewFields,
    parentContext: context,
  });

  return [formattedResults, evaluatedAttrs];
};

export const deleteObjects = async (
  {
    model,
    ids,
    context = {},
  }: {
    model: string;
    ids: number[];
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const userModel = new Model(model, client);

  return await userModel.delete(
    {
      ids,
      context,
    },
    requestConfig,
  );
};

export const update = async (
  options: UpdateObjectRequest,
  requestConfig: RequestConfig | undefined = {},
): Promise<void> => {
  const { id, model, values, fields, context } = options;

  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const userModel = new Model(model, client);

  const formattedValues = prepareWriteValues({ values, fields });

  return await userModel.write(
    {
      ids: [id],
      values: formattedValues,
      context,
    },
    requestConfig,
  );
};

export const create = async (
  options: CreateObjectRequest,
  requestConfig: RequestConfig | undefined = {},
): Promise<number> => {
  const { values, fields, model, context } = options;
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const userModel = new Model(model, client);
  const formattedValues = prepareWriteValues({ values, fields });
  const idCreated = await userModel.create(
    {
      values: formattedValues,
      context,
    },
    requestConfig,
  );

  // Check if idCreated is a number, otherwise throw an error
  if (typeof idCreated !== "number") {
    throw new Error("Invalid id created");
  }

  return idCreated;
};

export const createReport = async (
  options: CreateReportRequest,
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const report = new Report(client);
  return await report.create(options as CreateReportOpts, requestConfig);
};

export const getReport = async (
  options: GetReportRequest,
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const report = new Report(client);
  return await report.get(options, requestConfig);
};

export const executeOnChange = async (
  options: ExecuteOnChangeRequest,
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  const { model, ids, action, payload, fields } = options;

  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const modelObj = new Model(model, client);
  const result = await modelObj.executeOnChange(
    {
      action,
      payload: prepareWriteValues({ values: payload, fields }),
      ids,
    },
    requestConfig,
  );

  if (result.values) {
    const preparedValues = prepareRecord(
      {
        values: result.values,
        fields,
      },
      requestConfig,
    );
    return {
      ...result,
      values: preparedValues,
    };
  }
  return result;
};

export const defaultGet = async (
  options: DefaultGetRequest,
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }

  const { model, fields, context, extraValues } = options;
  const values = await execute(
    {
      model,
      action: "default_get",
      payload: Object.keys(fields),
      context,
    },
    requestConfig,
  );

  const object = { ...values, ...extraValues };

  return await prepareRecord({
    values: object,
    fields,
  });
};

export const getContext = async (
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }

  return await execute(
    {
      model: "res.users",
      action: "context_get",
    },
    requestConfig,
  );
};

export const nameSearch = async (
  {
    model,
    payload,
    context,
    attrs,
    limit,
    operator,
  }: {
    model: string;
    payload: any;
    attrs?: any;
    operator?: string;
    context?: any;
    limit?: number;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const modelObj = new Model(model, client);
  return await modelObj.name_search(
    {
      attrs,
      operator,
      payload,
      context,
      limit,
    },
    requestConfig,
  );
};

export const duplicate = async (
  {
    model,
    id,
    context,
  }: {
    model: string;
    id: number;
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const modelObj = new Model(model, client);
  return await modelObj.copy(
    {
      id,
      context,
    },
    requestConfig,
  );
};

export const evalDomain = async (
  {
    domain,
    values,
    context,
    fields,
  }: {
    domain: string;
    values: any;
    context?: any;
    fields?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any[]> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();

  if (domain === "[]") {
    return [];
  }

  const valuesToEval = fields
    ? prepareWriteValues({ values, fields, evalDomain: true })
    : values;

  // Now we add the fields that aren't keys in the values object as false
  // This is because the evalDomain function expects all fields to be present
  // in the values object
  if (fields) {
    Object.keys(fields).forEach((field) => {
      if (!valuesToEval[field]) {
        valuesToEval[field] = false;
      }
    });
  }

  return JSON.parse(
    await client.evalDomain(
      {
        domain,
        values: valuesToEval,
        context,
      },
      requestConfig,
    ),
  );
};

export const getLogInfo = async (
  {
    model,
    context,
    ids,
  }: {
    model: string;
    context: any;
    ids: number[];
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const modelObj = new Model(model, client);
  return await modelObj.perm_read(
    {
      ids,
      context,
    },
    requestConfig,
  );
};

export const treeButOpen = async (
  {
    model,
    context,
    id,
  }: {
    model: string;
    context: any;
    id: number;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  return await client.treeButOpen({ model, context, id }, requestConfig);
};

export const exportData = async (
  {
    model,
    context,
    fields,
    domain,
    limit,
    format,
  }: {
    model: string;
    context: any;
    fields?: string[];
    domain?: any[];
    limit?: number;
    format: string;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const modelObj = new Model(model, client);
  return await modelObj.export_data(
    {
      limit,
      fields,
      format,
      domain,
      context,
    },
    requestConfig,
  );
};

export const loginAndGetToken = async (
  {
    user,
    password,
    database,
  }: {
    user: string;
    password: string;
    database: string;
  },
  requestConfig: RequestConfig | undefined = {},
) => {
  const client = getClient();

  const token = await client?.loginAndGetToken(
    {
      user,
      password,
    },
    requestConfig,
  );

  if (!token) {
    throw new Error("Login failed");
  }
  return {
    token,
  };
};

export const getAvailableDatabases = async (
  _: unknown,
  requestConfig: RequestConfig | undefined = {},
): Promise<string[]> => {
  const client = getClient();
  return await client.getDatabases(requestConfig);
};

export const getLoginMessage = async (
  _: unknown,
  requestConfig: RequestConfig | undefined = {},
): Promise<string> => {
  const client = getClient();
  return await client.getLoginMessage(requestConfig);
};

export const getServerVersion = async (
  _: unknown,
  requestConfig: RequestConfig | undefined = {},
): Promise<string> => {
  const client = getClient();
  return await client.getServerVersion(requestConfig);
};

export const getRenewedToken = async (
  {
    token,
  }: {
    token: string;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<string> => {
  const client = getClient();
  return await client.refreshToken(token, requestConfig);
};

export const getActionStringForModel = async (
  model: string,
  requestConfig: RequestConfig | undefined = {},
): Promise<string> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const result = await execute(
    {
      model,
      action: "action_get",
      payload: undefined,
      context: undefined,
    },
    requestConfig,
  );

  if (result.id && result.type) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${result.type as string},${result.id.toString()}`;
  } else {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `ir.actions.act_window,${result.toString()}`;
  }
};

export const getRemoteConfig = async (
  _: unknown,
  requestConfig: RequestConfig | undefined = {},
): Promise<RemoteConfigState> => {
  const configUrl = import.meta.env.VITE_CONFIG_URL;

  if (configUrl) {
    const response = await axios.get(configUrl, requestConfig);
    return response.data as RemoteConfigState;
  } else {
    const client = getClient();
    return await client.getConfig(requestConfig);
  }
};

export const checkForFeatures = async (
  features: string[],
  requestConfig: RequestConfig | undefined = {},
): Promise<CheckFeaturesResponse> => {
  const client = getClient();
  return await client.checkForFeatures(features, requestConfig);
};

export const readForView = async (
  {
    model,
    context = {},
    domain,
    view_id,
  }: {
    domain: any[];
    model: string;
    context?: any;
    view_id: number;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const graphModel = new Model(model, client);
  return await graphModel.read_for_view({
    context,
    domain,
    view_id,
    version: VIEW_VERSION,
  });
};

export const applyRereadFieldsWithContext = async (
  {
    model,
    ids,
    results,
    viewFields,
    parentContext,
  }: {
    model: string;
    ids: number[];
    results: any[];
    viewFields: any;
    parentContext: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  const relationFieldsWithContext = getRelationFieldsWithContext(viewFields);
  const fieldsGroupedByContextValues = relationFieldsWithContext.reduce<
    Record<string, string[]>
  >((acc, fieldName) => {
    const contextValue = JSON.stringify(viewFields[fieldName].context);
    if (!acc[contextValue]) {
      acc[contextValue] = [];
    }
    acc[contextValue].push(fieldName);
    return acc;
  }, {});

  const erpModel = new Model(model, getClient());

  const resultsWithUpdatedValues = await Promise.all(
    Object.keys(fieldsGroupedByContextValues).map(async (context) => {
      const fieldsToRead = fieldsGroupedByContextValues[context];
      return await erpModel.read(
        {
          ids,
          fields: fieldsToRead,
          context: { ...parentContext, ...JSON.parse(context) },
        },
        requestConfig,
      );
    }),
  );

  const updatedResults = flattenById(resultsWithUpdatedValues);

  return results.map((result) => {
    const updatedResult = updatedResults.find(
      (updatedResult: any) => updatedResult.id === result.id,
    );

    if (!updatedResult) {
      return result;
    }

    return {
      ...result,
      ...updatedResult,
    };
  });
};

export const prepareResults = async (
  {
    model,
    ids,
    results,
    viewFields,
    parentContext,
  }: {
    model: string;
    ids: number[];
    results: any[];
    viewFields: any;
    parentContext: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  const objectWithUpdatedValues = await applyRereadFieldsWithContext(
    {
      model,
      ids,
      results,
      viewFields,
      parentContext,
    },
    requestConfig,
  );

  return await Promise.all(
    objectWithUpdatedValues.map(async (result: any) => {
      return await prepareRecord({
        values: result,
        fields: viewFields,
      });
    }),
  );
};

const prepareRecord = async (
  {
    fields,
    values,
  }: {
    fields: any;
    values: any;
  },
  requestConfig: RequestConfig | undefined = {},
) => {
  if (!fields) {
    return values;
  }

  const formattedValues = prepareReadValues({
    values,
    fields,
  });

  return await applyNameGetMany2One(
    {
      fields,
      values: formattedValues,
    },
    requestConfig,
  );
};

export const readAggregates = async (
  {
    model,
    domain,
    aggregateFields,
  }: {
    model: string;
    domain: any[];
    aggregateFields: Record<string, string[]>;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<Record<string, Record<string, number>>> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  const graphModel = new Model(model, client);
  return await graphModel.read_agg(
    {
      domain,
      aggregate_fields: aggregateFields,
    },
    requestConfig,
  );
};

export const readUserViewPrefs = async (
  {
    key,
  }: {
    key: string;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  return await client.readViewPrefs(
    {
      key,
    },
    requestConfig,
  );
};

export const saveUserViewPrefs = async (
  {
    key,
    preferences,
  }: {
    key: string;
    preferences: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<any> => {
  await renewTokenIfNeeded(requestConfig);
  if (redirectIfInvalidToken()) {
    throw new Error("Invalid token");
  }
  const client = getClient();
  return await client.saveViewPrefs(
    {
      key,
      preferences,
    },
    requestConfig,
  );
};
