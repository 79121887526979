import { APIResult, useErpState } from "@/hooks/erp/useErp";
import { useCallback, useEffect, useState } from "react";
import { Domain } from "@/types/base";
import { useUserInfo } from "@/redux/slices/userInfoSlice";

export const useReadUserMenuDomain = ({
  userMenuId,
}: {
  userMenuId: number;
}): [APIResult<Domain>, () => void, () => void] => {
  const { context } = useUserInfo();

  // Final result of the hook
  const [result, setResult] = useState<APIResult<Domain>>({
    data: null,
    loading: false,
    error: null,
  });

  // Request1: It will retrieve the action type
  const [request1Result, fetchRequest1, cancelRequest1] =
    useErpState("readObjects");

  // Request2: It will retrieve the domain depending on the action type retrieved in the first request
  const [request2Result, fetchRequest2, cancelRequest2] =
    useErpState("readObjects");

  // Request3: it will evaluate the domain
  const [request3Result, fetchRequest3, cancelRequest3] =
    useErpState("evalDomain");

  // We ignore the loading from each request and we control the loading from the hook
  const { data: request1Data, error: request1Error } = request1Result;
  const { data: request2Data, error: request2Error } = request2Result;
  const { data: request3Data, error: request3Error } = request3Result;

  // We export this fetchData function in order to fire the first request
  const fetchData = () => {
    // We set loading to true and restore the result to its initial state
    setResult({
      data: null,
      loading: true,
      error: null,
    });

    void fetchRequest1({
      model: "ir.actions.actions",
      ids: [userMenuId],
      context,
    });
  };

  const cancelRequests = useCallback(() => {
    cancelRequest1();
    cancelRequest2();
    cancelRequest3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If one of the requests fails, we set the error in the final result
  useEffect(() => {
    const error = request1Error || request2Error || request3Error;

    if (!error) {
      return;
    }

    setResult((prevResult) => {
      return {
        ...prevResult,
        loading: false,
        error,
      };
    });
  }, [request1Error, request2Error, request3Error]);

  // When we recieve the data for the first request, we set the action type in our internal state
  useEffect(() => {
    if (request1Data) {
      const { type } = request1Data[0];
      void fetchRequest2({
        model: type,
        ids: [userMenuId],
        context,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request1Data]);

  useEffect(() => {
    if (request2Data) {
      const { domain } = request2Data[0];
      void fetchRequest3({
        domain,
        values: {},
        context: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request2Data]);

  useEffect(() => {
    if (request3Data) {
      const parsedDomain = request3Data;
      setResult({
        error: null,
        loading: false,
        data: parsedDomain,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request3Data]);

  return [result, fetchData, cancelRequests];
};
