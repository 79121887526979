import { Spin } from "antd";
import React, { useEffect } from "react";

import { LoginContainer } from "./LoginContainer";
import { LoginForm } from "./LoginForm";
import { useAuth } from "@/context/auth/AuthContext";

const Login = (): React.ReactElement => {
  const {
    fetchData,
    loading,
    openIdLoading,
    formIsLoading,
    form,
    submitForm,
    formError,
    serverError,
    loginMessage,
    serverVersion,
    databaseSelected,
    setDatabaseSelected,
    databases,
    onLoginWithOpenId,
    mount,
  } = useAuth();

  useEffect(() => {
    mount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = () => {
    if (loading || openIdLoading) {
      return (
        <div className="flex justify-center w-full h-full p-6">
          <Spin />
        </div>
      );
    }

    return (
      <LoginForm
        form={form}
        formIsLoading={formIsLoading}
        onSubmitForm={submitForm}
        formError={formError}
        serverError={serverError}
        loginMessage={loginMessage}
        onRetryConnection={() => {
          void fetchData?.();
        }}
        onChangeDatabaseSelected={setDatabaseSelected}
        databaseSelected={databaseSelected}
        databases={databases}
        onLoginWithOpenId={() => {
          void onLoginWithOpenId?.();
        }}
        serverVersion={serverVersion}
      />
    );
  };

  return (
    <LoginContainer>
      <div className="w-full justify-start">{content()}</div>
    </LoginContainer>
  );
};

export default Login;
