import { useHydrateContext } from "@/hooks/useHydrateContext";
import { useHydrateUser } from "@/hooks/useHydrateUser";
import { useLocale } from "@gisce/react-formiga-components";
import { useUserInfo } from "@/redux/slices/userInfoSlice";
import { FormModal } from "@gisce/react-ooui";
import { useEffect } from "react";

export type PreferencesModalProps = {
  visible: boolean;
  close: () => void;
};

export const PreferencesModal = ({ visible, close }: PreferencesModalProps) => {
  const { user, context } = useUserInfo();

  const { t } = useLocale();
  const [, fetchAndUpdateContext, cancelHydrateContextRequest] =
    useHydrateContext();
  const [, fetchAndUpdateUser, cancelHydrateUserRequest] = useHydrateUser();

  useEffect(() => {
    return () => {
      cancelHydrateContextRequest();
      cancelHydrateUserRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      id={user?.id}
      visible={visible}
      title={t("preferences")}
      model="res.users"
      onCancel={close}
      parentContext={context}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmitSucceed={async () => {
        close();
        fetchAndUpdateContext();
        fetchAndUpdateUser();
      }}
      getDataFromAction
    />
  );
};
