let navigateFn: ((to: string) => void) | null = null;

export const setNavigate = (navigate: (to: string) => void) => {
  navigateFn = navigate;
};

export const navigateTo = (path: string) => {
  if (navigateFn) {
    navigateFn(path);
  } else {
    console.error("Navigate function has not been set");
  }
};
