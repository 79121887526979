import { RootState } from "@/redux/store";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useUserId = () => {
  const { token } = useSelector((state: RootState) => state.connectionSettings);
  const [userId, setUserId] = useState<number>();

  useEffect(() => {
    if (token) {
      const { uid: userId } = jwtDecode<JwtPayload>(token) as any;
      setUserId(userId as number);
    }
  }, [token]);

  return userId;
};
