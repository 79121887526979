import { MenuItem, MenuItemUi } from "@/models/menuItem";
import { APIResult, useErpState } from "@/hooks/erp/useErp";
import { useCallback, useEffect, useState } from "react";
import { useReadUserMenuDomain } from "./useReadUserMenuDomain";
import { useUserInfo } from "@/redux/slices/userInfoSlice";
import { getCompoundId } from "@/helpers/menuHelper";

export const useReadInitialMenuItems = (): [
  APIResult<MenuItemUi[]>,
  () => void,
  () => void,
] => {
  // Final result of the hook
  const [result, setResult] = useState<APIResult<MenuItemUi[]>>({
    data: null,
    loading: false,
    error: null,
  });
  // Internal data retrieved from the API
  const { context, user } = useUserInfo();
  const [userMenuId] = user?.menu_id || [];

  // 2 requests:
  // 1. Retrieve initial domain for the menu
  // 2. Retrieve initial menu items for this domain

  // Request1: Retrieve initial domain for the menu
  const [request1Result, fetchRequest1, cancelRequest1] = useReadUserMenuDomain(
    {
      userMenuId,
    },
  );

  // Request2: Retrieve initial menu items for this domain
  const [request2Result, fetchRequest2, cancelRequest2] = useErpState("search");

  // We ignore the loading from each request and we control the loading from the hook
  const { data: request1Data, error: request1Error } = request1Result;
  const { data: request2Data, error: request2Error } = request2Result;

  // If one of the requests fails, we set the error in the final result
  useEffect(() => {
    const error = request1Error || request2Error;

    if (!error) {
      return;
    }

    setResult((prevResult) => {
      return {
        ...prevResult,
        loading: false,
        error,
      };
    });
  }, [request1Error, request2Error]);

  const cancelRequests = useCallback(() => {
    cancelRequest1();
    cancelRequest2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () => {
    setResult({
      loading: true,
      data: null,
      error: null,
    });
    fetchRequest1();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // If the first request is successful, we set the domain and fire the second request
  useEffect(() => {
    if (!request1Data) {
      return;
    }
    void fetchRequest2({
      model: "ir.ui.menu",
      fieldsToRetrieve: [
        "name",
        "id",
        "icon",
        "child_id",
        "action",
        "parent_id",
      ],
      context,
      params: request1Data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request1Data]);

  useEffect(() => {
    if (!request2Data) {
      return;
    }

    const initialMenuItems: MenuItemUi[] = (request2Data as MenuItem[]).map(
      (item) => {
        const { child_id, parent_id, id, ...rest } = item;
        return {
          id: item.id.toString(),
          child_id: item.child_id?.map((childId: number) =>
            getCompoundId({
              parentId: item.id.toString(),
              childId: childId.toString(),
            }),
          ),
          ...rest,
        };
      },
    );

    setResult({
      loading: false,
      data: initialMenuItems,
      error: null,
    });
  }, [request2Data]);

  return [result, fetchData, cancelRequests];
};
