export default {
  welcome: "Welcome",
  sessionExpired: "Your session has expired. Please log in again.",
  inputUsername: "Please input your username",
  inputPassword: "Please input your password",
  username: "Username",
  password: "Password",
  database: "Database",
  loading: "Loading...",
  login: "Login",
  preferences: "Preferences",
  confirmLangRefresh:
    "In order to reflect the language changes, you must refresh the page. Do you want to refresh it now?",
  logout: "Logout",
  default: "Default",
  addCustomHost: "Add custom host",
  editCustomHost: "Edit custom host",
  removeCustomHost: "Remove custom host",
  hostName: "Host name description",
  host: "Host",
  errorHost: "Host already exists",
  startRecording: "Start recording",
  stopRecording: "Stop recording",
  recordingInProgress: "Recording in progress...",
  recordingOptions: "Recording options",
  recordingName: "Recording name",
  acceptRecordingTerms: "I accept recording this session to debug a problem",
  recordingTerms: "privacy policy of the recording",
  recordingPrivacyUrl: "https://www.google.com/policies/privacy/",
  recordingMustAcceptTos: "You must accept the",
  updateAvailable: "Update available",
  updateAvailableDescription: "There is a new version of the web application.",
  updateButton: "Update",
  tabsPendingSave:
    "If you have tabs with unsaved changes, they will be lost. Do you want to continue anyway to update the version?",
  discardAndUpdate: "Discard changes and update",
  ignore: "Ignore",
};
