import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import connectionSettingsSlice from "./slices/connectionSettingsSlice";
import tabSlice from "./slices/tabSlice";
import userInfoSlice from "./slices/userInfoSlice";
import remoteConfigSlice from "./slices/remoteConfigSlice";
import erpFeaturesSlice from "./slices/erpFeaturesSlice";

const persistedConnectionSettingsReducer = persistReducer(
  {
    key: "connectionSettings",
    storage,
  },
  connectionSettingsSlice,
);

export const store = configureStore({
  reducer: {
    tabs: tabSlice,
    connectionSettings: persistedConnectionSettingsReducer,
    userInfo: userInfoSlice,
    remoteConfig: remoteConfigSlice,
    erpFeatures: erpFeaturesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["persist/PERSIST"],
      },
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
