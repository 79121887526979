import { CSSProperties } from "react";
import { Omnisearch, OmnisearchProps } from "./Omnisearch";

export const OmnisearchModal = (props: OmnisearchProps) => {
  const { visible } = props;

  const modalStyles: CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: `rgba(0, 0, 0, ${visible ? 0.8 : 0})`,
    transition: "background-color 0.2s ease",
    opacity: visible ? 1 : 0,
    zIndex: 100,
    visibility: visible ? "visible" : "hidden",
  };

  return (
    <div>
      <div style={modalStyles}>
        <Omnisearch {...props} />
      </div>
    </div>
  );
};
