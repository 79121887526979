import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useJwtToken } from "@/hooks/useJwtToken";

export const PublicComponent = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useJwtToken();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [navigate, isAuthenticated]);

  return <>{!isAuthenticated && children}</>;
};
