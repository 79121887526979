import { ErpFeatureKeys, ErpFeaturesMap } from "@gisce/react-ooui";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ErpFeaturesState = ErpFeaturesMap;

const initialState: ErpFeaturesState = Object.values(ErpFeatureKeys).reduce(
  (acc, key) => {
    // Here key is the value of the enum, which we use directly
    acc[key] = false;
    return acc;
  },
  // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter, @typescript-eslint/consistent-type-assertions
  {} as ErpFeaturesState,
);

export const erpFeaturesSlice = createSlice({
  name: "erpFeatures",
  initialState,
  reducers: {
    setFeatureState: (
      state,
      action: PayloadAction<{ feature: ErpFeatureKeys; isEnabled: boolean }>,
    ) => {
      const { feature, isEnabled } = action.payload;
      state[feature] = isEnabled;
    },
  },
});

export const { setFeatureState } = erpFeaturesSlice.actions;

export default erpFeaturesSlice.reducer;
