import jwtDecode, { JwtPayload } from "jwt-decode";
import { store } from "@/redux/store";

export const tokenIsExpired = (token: string | null): boolean | null => {
  try {
    if (!token) return null;
    const decodedToken = jwtDecode<JwtPayload>(token, { header: true });
    return Date.now() >= decodedToken.exp! * 1000;
  } catch (e) {
    return false;
  }
};

export const tokenIsAboutToExpire = (token: string | null): boolean | null => {
  if (!token) return null;
  const decodedToken = jwtDecode<JwtPayload>(token, { header: true });
  const state = store.getState();
  const { refreshTokenSecondsThreshold } = state.remoteConfig;

  if (tokenIsExpired(token)) {
    return false;
  }

  return (
    Date.now() + refreshTokenSecondsThreshold * 1000 >= decodedToken.exp! * 1000
  );
};

export default {
  tokenIsExpired,
  tokenIsAboutToExpire,
};
