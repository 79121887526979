export class TimeoutError extends Error {
  constructor(message: string) {
    super(message); // Pass the message to the Error class constructor
    this.name = "TimeoutError"; // Set the error name

    // Set the prototype explicitly (important for instanceof checks)
    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}

export function getErrorMessage(err: any) {
  if (!err) {
    return;
  }

  // If it's a string, return it directly
  if (typeof err === "string") {
    return err;
  }

  // If it's an instance of the Error class (or a subclass), return its message
  if (err instanceof Error) {
    return `${err.name} - ${err.message}`;
  }

  // Try converting it to a string for other types (e.g. objects, numbers, etc.)
  return String(err);
}
