import BaseMenuEntry, { MenuEntryProps } from "@/components/menu/baseMenuEntry";
import { UnorderedListOutlined } from "@ant-design/icons";
import React from "react";

const MenuEntry = (props: MenuEntryProps): React.ReactElement => {
  const { color, onClick } = props;
  const {
    icon = <UnorderedListOutlined className={`text-lg`} style={{ color }} />,
  } = props;

  return (
    <BaseMenuEntry
      {...props}
      icon={icon}
      onClick={onClick}
      isExpandable={false}
    />
  );
};

export default MenuEntry;
