import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { getBooleanEntry, getNumericEntry } from "@/hooks/useFetchRemoteConfig";

export interface RemoteConfigState {
  title: string; // "Webclient" by default, used for the title of the page
  name: string; // "gisce-webclient" by default, used for network requests header

  // General behaviour
  refreshTokenSecondsThreshold: number; // 60 seconds by default
  loginTimeout: number; // 200000 milliseconds by default
  allowCustomHosts: boolean; // false by default

  // Visual settings
  themeMode: "default" | "compact"; // "compact" by default
  primaryColor: string; // "#001529" by default
  disableAutoCollapseMenu: boolean; // false by default

  // OPTIONAL: OpenReplay configuration
  openreplayApiKey?: string;

  // OPTIONAL: OpenID SSO configuration
  openidAuthorityUrl?: string;
  openidClientId?: string;
  openidFullLogout?: boolean;
}

export const initialState: RemoteConfigState = {
  title: import.meta.env.VITE_TITLE || "Webclient",
  name: import.meta.env.VITE_NAME || "gisce-webclient",
  refreshTokenSecondsThreshold:
    getNumericEntry(import.meta.env.VITE_REFRESH_TOKEN_SECONDS_THRESHOLD) ||
    300,
  themeMode: import.meta.env.VITE_THEME_MODE || "compact",
  disableAutoCollapseMenu:
    getBooleanEntry(import.meta.env.VITE_DISABLE_AUTOCOLLAPSE_MENU) || false,
  allowCustomHosts:
    getBooleanEntry(import.meta.env.VITE_ALLOW_CUSTOM_HOSTS) || false,
  loginTimeout: getNumericEntry(import.meta.env.VITE_LOGIN_TIMEOUT) || 20000,
  primaryColor: import.meta.env.VITE_PRIMARY_COLOR || "#001529",
};

export const remoteConfigSlice = createSlice({
  name: "remoteConfig",
  initialState,
  reducers: {
    setRemoteConfig: (state, action) => {
      return {
        ...state,
        ...removeUndefinedProperties(action.payload),
      };
    },
  },
});

export const { setRemoteConfig } = remoteConfigSlice.actions;

export default remoteConfigSlice.reducer;

export const useRemoteConfig = () => {
  return useSelector((state: RootState) => state.remoteConfig);
};

export function removeUndefinedProperties<T>(obj: T): Partial<T> {
  const result: Partial<T> = {};

  for (const key in obj) {
    if (obj[key] !== undefined) {
      result[key as keyof T] = obj[key];
    }
  }

  return result;
}
