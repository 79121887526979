import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useErpState } from "./erp/useErp";
import {
  RemoteConfigState,
  removeUndefinedProperties,
  setRemoteConfig,
} from "@/redux/slices/remoteConfigSlice";

export const useFetchRemoteConfig = (): { loading: boolean } => {
  const dispatch = useDispatch();

  const [remoteConfigResult, fetchRemoteConfig, cancelRemoteConfigRequest] =
    useErpState("getRemoteConfig", {
      auth: false,
      initialState: {
        loading: true,
      },
    });

  const [loading, setLoading] = useState(true);
  const { data, error } = remoteConfigResult;

  useEffect(() => {
    fetchRemoteConfig();
    return () => {
      cancelRemoteConfigRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(
        setRemoteConfig({
          ...data,
          ...removeUndefinedProperties(getRemoteConfigFromEnv()),
        }),
      );
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      // If the endpoint does not support configuration, we'll ignore it
      console.error({ remoteConfigError: error });
      setLoading(false);
    }
  }, [error]);

  return { loading };
};

export function getRemoteConfigFromEnv(): Partial<RemoteConfigState> {
  return {
    title: import.meta.env.VITE_TITLE,
    name: import.meta.env.VITE_NAME,
    refreshTokenSecondsThreshold: getNumericEntry(
      import.meta.env.VITE_REFRESH_TOKEN_SECONDS_THRESHOLD,
    ),
    loginTimeout: getNumericEntry(import.meta.env.VITE_LOGIN_TIMEOUT),
    allowCustomHosts: getBooleanEntry(import.meta.env.VITE_ALLOW_CUSTOM_HOSTS),
    themeMode: import.meta.env.VITE_THEME_MODE,
    primaryColor: import.meta.env.VITE_PRIMARY_COLOR,
    disableAutoCollapseMenu: getBooleanEntry(
      import.meta.env.VITE_DISABLE_AUTOCOLLAPSE_MENU,
    ),
    openreplayApiKey: import.meta.env.VITE_OPENREPLAY_APIKEY,
    openidAuthorityUrl: import.meta.env.VITE_OPENID_AUTHORITY_URL,
    openidClientId: import.meta.env.VITE_OPENID_CLIENT_ID,
    openidFullLogout:
      import.meta.env.VITE_OPENID_FULL_LOGOUT === "true" ?? false,
  };
}

export function getNumericEntry(value: string | undefined) {
  return value ? parseInt(value) : undefined;
}

export function getBooleanEntry(value: string | undefined) {
  return value ? value === "true" : undefined;
}
