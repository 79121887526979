import { RequestConfig, create, deleteObjects } from "@/services/erpService";

import { getClient } from "./powerpClient";

export type ShortcutApi = {
  action_id: number;
  action_type: string;
  id: number;
  name: string;
  res_id: number | boolean;
  res_model: string;
  view_id: number;
  view_type: string;
};

export async function getShortcuts(
  {
    context = {},
  }: {
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<ShortcutApi[]> {
  const client = getClient();
  const shortcuts = await client?.getShortcuts({ context }, requestConfig);
  return shortcuts;
}

export async function isShortcutFavorite(
  {
    action_id,
    action_type,
    res_id,
    view_id,
    context = {},
  }: {
    action_id: number;
    action_type: string;
    res_id: number | boolean;
    view_id: number;
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<number | boolean> {
  const client = getClient();
  const result = await client?.isShortcutFavorite(
    {
      payload: {
        action_id,
        action_type,
        res_id,
        view_id,
      },
      context,
    },
    requestConfig,
  );
  return result;
}

export async function addFavourite(
  {
    action_id,
    action_type,
    res_id,
    view_id,
    context = {},
  }: {
    action_id: number;
    action_type: string;
    res_id: number | boolean;
    view_id: number;
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<number> {
  return await create(
    {
      model: "ir.ui.view_sc",
      context,
      values: {
        action_id,
        action_type,
        res_id,
        view_id,
      },
    } as any,
    requestConfig,
  );
}

export async function removeFavourite(
  {
    shortcut_id,
    context = {},
  }: {
    shortcut_id: number;
    context?: any;
  },
  requestConfig: RequestConfig | undefined = {},
): Promise<void> {
  return await deleteObjects(
    {
      model: "ir.ui.view_sc",
      ids: [shortcut_id],
      context,
    },
    requestConfig,
  );
}
