import { Layout } from "antd";
import React from "react";

import TreeViewMenu from "@/components/common/TreeViewMenu";
import MenuProvider from "@/components/menu/menuContext";
import { MenuItemUi } from "@/models/menuItem";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";

const { Sider } = Layout;

type Props = {
  width?: number;
  className?: string;
  onMenuItemClicked: (menuItem: MenuItemUi) => void;
  style?: React.CSSProperties;
};

export const SIDEBAR_WIDTH = 400;

function Sidebar(props: Props): React.ReactElement {
  const { width, className, onMenuItemClicked, style } = props;

  return (
    <Sider width={width} className={className} style={style}>
      <ErrorBoundary>
        <MenuProvider>
          <TreeViewMenu onItemClicked={onMenuItemClicked} />
        </MenuProvider>
      </ErrorBoundary>
    </Sider>
  );
}

export default Sidebar;
