import MenuHelper, {
  getParentItemsWithUpdatedChild,
} from "@/helpers/menuHelper";
import { useErpState } from "@/hooks/erp/useErp";
import { useReadInitialMenuItems } from "@/hooks/menu/useReadInitialMenuItems";
import { MenuItemUi } from "@/models/menuItem";
import { useUserInfo } from "@/redux/slices/userInfoSlice";
import { AxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

export type MenuContextType = {
  toggleOpenedKey: (key: string) => void;
  keyIsOpened: (key: string) => boolean;
  menuItems: MenuItemUi[];
  error: AxiosError | null;
  loading: boolean;
};

export const MenuContext = React.createContext<MenuContextType | null>(null);

const MenuProvider = ({ children }: { children: React.ReactNode }): any => {
  const { context, user } = useUserInfo();
  const [openedKeys, setOpenedKeys] = useState<string[]>([]);
  const [loadedKeys, setLoadedKeys] = useState<string[]>([]);
  const [keysToLoad, setKeysToLoad] = useState<string[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItemUi[]>([]);

  const [initialResut, fetchInitialData, cancelInitialRequest] =
    useReadInitialMenuItems();
  const { data: initialMenuItems, error, loading } = initialResut;

  useDeepCompareEffect(() => {
    if (user && context) {
      fetchInitialData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, context]);

  useEffect(() => {
    return () => {
      cancelInitialRequest();
      cancelRequest();
    }; // When component unmounts, cancel request
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialMenuItems) {
      return;
    }
    setMenuItems(initialMenuItems);

    // If it's a rerender due to context change and we have loadedKeys,
    // we must set the keysToLoad to the loadedKeys in order to refetch them
    if (loadedKeys.length > 0) {
      setKeysToLoad(loadedKeys);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMenuItems]);

  const [itemsRequest, fetchItems, cancelRequest] = useErpState("search");

  const { data: itemsData, error: itemsError } = itemsRequest;

  useEffect(() => {
    if (!itemsError) {
      return;
    }
    console.error(itemsError);
    setKeysToLoad([]);
  }, [itemsError]);

  useEffect(() => {
    if (openedKeys.length === 0) {
      return;
    }

    // We must search the openedKeys for the ones that aren't loaded in loadedKeys and aren't loading in loadingKeys
    const keysToAdd = openedKeys.filter(
      (key) => !loadedKeys.includes(key) && !keysToLoad.includes(key),
    );

    setKeysToLoad((prevKeysToLoad) => [...prevKeysToLoad, ...keysToAdd]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedKeys]);

  useEffect(() => {
    if (!keysToLoad || keysToLoad.length === 0) {
      return;
    }
    void fetchItems({
      model: "ir.ui.menu",
      fieldsToRetrieve: [
        "name",
        "id",
        "icon",
        "child_id",
        "action",
        "parent_id",
      ],
      context,
      params: [
        [
          "parent_id",
          "in",
          keysToLoad.map((k) => parseInt(MenuHelper.getChildestId(k))),
        ],
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysToLoad]);

  useEffect(() => {
    if (!itemsData) {
      return;
    }

    updateMenuItemsFetchedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsData]);

  const updateMenuItemsFetchedData = useCallback(() => {
    const updatedParentItems = getParentItemsWithUpdatedChild({
      itemsData,
      menuItems,
    });

    setMenuItems((prevMenuItems) =>
      MenuHelper.updateDeepMenuItems(updatedParentItems, prevMenuItems),
    );
    setKeysToLoad([]);
    setLoadedKeys((prevLoadedKeys) => [...prevLoadedKeys, ...keysToLoad]);
  }, [itemsData, keysToLoad, menuItems]);

  const toggleOpenedKey = useCallback(
    (key: string) => {
      if (keysToLoad.length > 0) {
        return;
      }
      if (!openedKeys.includes(key)) {
        setOpenedKeys((prevOpenedKeys) => {
          return [...prevOpenedKeys, key];
        });
      } else {
        setOpenedKeys((prevOpenedKeys) =>
          prevOpenedKeys.filter((k) => k !== key),
        );
      }
    },
    [openedKeys, keysToLoad],
  );

  const keyIsOpened = useCallback(
    (key: string) => {
      return openedKeys.includes(key);
    },
    [openedKeys],
  );

  return (
    <MenuContext.Provider
      value={{
        toggleOpenedKey,
        keyIsOpened,
        menuItems,
        error,
        loading,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuProvider;

export const useMenuContext = (): MenuContextType => {
  const context = React.useContext(MenuContext);
  if (context === null) {
    throw new Error("useMenuContext must be used within a MenuProvider");
  }
  return context;
};
