import * as erpService from "@/services/erpService";
import * as shortcutService from "@/services/shortcutService";
import { ConnectionProvider } from "@gisce/react-ooui";

const init = (): void => {
  ConnectionProvider.init({
    ...erpService,
    ...shortcutService,
  } as any);
};

export default { init };
