import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Alert, Button, Form, Input, Select, Typography } from "antd";

import { CustomHostsOptions } from "./CustomHosts/CustomHostsOptions";
import { useLocale } from "@gisce/react-formiga-components";
import { useRemoteConfig } from "@/redux/slices/remoteConfigSlice";
import { useJwtToken } from "@/hooks/useJwtToken";

const { Option } = Select;
const { Text } = Typography;

export type LoginFormProps = {
  serverError: string | null;
  loginMessage: string | null;
  onRetryConnection: () => void;
  form: any;
  onSubmitForm: (values: any) => Promise<void>;
  formError: string | null;
  formIsLoading: boolean;
  databases: string[];
  onChangeDatabaseSelected: (value: string) => void;
  databaseSelected: string;
  onLoginWithOpenId: () => void;
  serverVersion: string;
};

export const LoginForm = (props: LoginFormProps) => {
  const {
    serverError,
    loginMessage,
    onRetryConnection,
    formError,
    onSubmitForm,
    form,
    formIsLoading,
    databases,
    onChangeDatabaseSelected,
    databaseSelected,
    onLoginWithOpenId,
    serverVersion,
  } = props;
  const { t } = useLocale();
  const { openidAuthorityUrl, allowCustomHosts } = useRemoteConfig();
  const { tokenIsExpired } = useJwtToken();

  if (serverError !== null) {
    return (
      <>
        <Alert className="mb-8" message={serverError} type="error" showIcon />
        {allowCustomHosts && <CustomHostsOptions />}
        <Form.Item className="flex justify-center w-full pb-0 mt-8 mb-0">
          <Button
            size="large"
            htmlType="submit"
            className="w-full"
            type="primary"
            onClick={onRetryConnection}
          >
            {"Reintentar"}
          </Button>
        </Form.Item>
      </>
    );
  }

  return (
    <>
      <Text className="flex justify-center w-full text-center">
        {loginMessage || t("welcome")}
      </Text>
      <Text
        type="secondary"
        className="flex justify-center w-full pb-6 text-xs leading-normal text-center"
      >
        {`${serverVersion}`}
      </Text>
      {formError !== null ? (
        <Alert className="mb-8" message={formError} type="error" showIcon />
      ) : null}
      {tokenIsExpired ? (
        <Alert
          className="mb-8"
          message={t("sessionExpired")}
          type="warning"
          showIcon
        />
      ) : null}
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          void onSubmitForm(values);
        }}
      >
        <Form.Item
          label={t("username")}
          name="user"
          rules={[{ required: true, message: t("inputUsername") }]}
        >
          <Input autoComplete="username" size="large" />
        </Form.Item>
        <Form.Item
          label={t("password")}
          name="password"
          rules={[{ required: true, message: t("inputPassword") }]}
        >
          <Input.Password
            autoComplete="current-password"
            size="large"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        {allowCustomHosts && <CustomHostsOptions />}
        <Form.Item label={t("database")} name="database">
          <Select
            size="large"
            className="flex justify-center w-full"
            onChange={(value: string) => onChangeDatabaseSelected(value)}
            value={databaseSelected}
          >
            {databases.map((dbName, i) => {
              return (
                <Option key={i} value={dbName}>
                  {dbName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <div className="flex justify-center gap-2 pt-4">
          <Form.Item className="flex justify-center">
            <Button
              size="large"
              htmlType="submit"
              className="w-full"
              type="primary"
              loading={formIsLoading}
              disabled={formIsLoading}
            >
              {t("Login")}
            </Button>
          </Form.Item>
          {openidAuthorityUrl && (
            <Form.Item className="flex justify-center">
              <Button
                size="large"
                className="w-full"
                disabled={formIsLoading}
                onClick={onLoginWithOpenId}
              >
                Login with OpenID
              </Button>
            </Form.Item>
          )}
        </div>
      </Form>
    </>
  );
};
