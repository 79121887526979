export default {
  welcome: "Benvingut",
  sessionExpired: "La sessió ha expirat. Siusplau, torna a iniciar sessió.",
  inputUsername: "Introdueix el nom d'usuari",
  inputPassword: "Introdueix la contrassenya",
  username: "Usuari",
  password: "Contrasenya",
  database: "Base de dades",
  loading: "Carregant...",
  login: "Inicia sessió",
  preferences: "Preferències",
  confirmLangRefresh:
    "Per tal d'actualitzar l'idioma, és necessari que es refresqui la pàgina. Vols refrescar-la ara?",
  logout: "Tancar sessió",
  default: "Per defecte",
  addCustomHost: "Afegir host personalitzat",
  editCustomHost: "Editar host personalitzat",
  removeCustomHost: "Eliminar host personalitzat",
  hostName: "Nom del host",
  host: "Host",
  errorHost: "El host ja existeix",
  startRecording: "Començar grabació",
  stopRecording: "Aturar grabació",
  recordingInProgress: "Grabació en curs...",
  recordingOptions: "Opcions de la grabació",
  recordingName: "Nom de la grabació",
  acceptRecordingTerms:
    "Accepto grabar la meva sessió per tal de depurar un problema",
  recordingTerms: "política de privacitat de la grabació",
  recordingPrivacyUrl: "https://www.google.com/policies/privacy/",
  recordingMustAcceptTos: "Has d'acceptar la",
  updateAvailable: "Actualització disponible",
  updateAvailableDescription: "Hi ha una nova versió de l'aplicació web.",
  updateButton: "Actualitzar",
  tabsPendingSave:
    "Si tens pestanyes amb canvis sense desar, aquests es perdran. Vols continuar de totes maneres per actualitzar la versió?",
  discardAndUpdate: "Descartar canvis i actualitzar",
  ignore: "Ignorar",
};
