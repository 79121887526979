import { Form } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";

import { hasCodeInUrl } from "@/context/auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setDatabase,
  setToken,
  useConnectionSettings,
} from "@/redux/slices/connectionSettingsSlice";
import { useErpState } from "@/hooks/erp/useErp";
import { usePreLoginInfo } from "./usePreLoginInfo";
import { TimeoutError, getErrorMessage } from "@/helpers/error";

type UseLoginProps = { signInOpenId: () => void; signOutOpenId: () => void };

export const useLogin = ({ signInOpenId, signOutOpenId }: UseLoginProps) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { currentHost } = useConnectionSettings();

  const [loading, setLoading] = useState<boolean>(true);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const [openIdLoading, setOpenIdLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | null>(null);

  const [databases, setDatabases] = useState<string[]>([]);
  const [serverVersion, setServerVersion] = useState<string>("");
  const [loginMessage, setLoginMessage] = useState<string>("");
  const [serverError, setServerError] = useState<string | null>(null);
  const [databaseSelected, setDatabaseSelected] = useState<string>("");
  const hostRef = useRef<string>(currentHost);
  const dispatch = useDispatch();

  const [tokenRetreievedResult, loginAndGetToken, cancelRequest] = useErpState(
    "loginAndGetToken",
    { auth: false },
  );

  const [fetchPreLoginData, cancelPreLoginRequest] = usePreLoginInfo();

  const { database: persistedDatabase } = useConnectionSettings();

  // This will be fired when the user is redirected back from the identity provider
  useEffect(() => {
    if (hasCodeInUrl(window.location)) {
      setOpenIdLoading(true);
    }
    return () => {
      cancelRequest();
      cancelPreLoginRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This will be fired when the user change the host to connect to
  useEffect(() => {
    if (hostRef.current === currentHost) {
      return;
    }

    hostRef.current = currentHost;
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHost]);

  const mount = useCallback(async () => {
    if (hasCodeInUrl(window.location)) {
      // If we have a code in the url, it means that the user is coming back from the identity provider
      return;
    }
    signOutOpenId?.();
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const preLoginResult = await fetchPreLoginData();

      const { databases, loginMessage, serverVersion } = preLoginResult || {};

      setDatabases(databases);
      const databaseToSelect = persistedDatabase || databases[0];
      setDatabaseSelected(databaseToSelect);
      dispatch(setDatabase(databaseToSelect));
      setServerVersion(serverVersion);
      setLoginMessage(loginMessage);
      form.setFieldsValue({ database: databases[0] });
      setServerError(null);
      setLoading(false);

      return {
        databases,
        serverVersion,
        loginMessage,
      };
    } catch (err) {
      if (err?.name === "CanceledError") {
        /* empty */
      } else if (err instanceof TimeoutError) {
        setServerError(`Timeout while reaching ${currentHost}`);
      } else {
        setServerError(err && getErrorMessage(err));
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPreLoginData, form, currentHost]);

  useEffect(() => {
    if (tokenRetreievedResult.error) {
      signOutOpenId?.();
      setFormError(getErrorMessage(tokenRetreievedResult.error)!);
      setFormIsLoading(false);
      return;
    }

    if (!tokenRetreievedResult.loading) {
      setFormIsLoading(false);
    }

    const token = tokenRetreievedResult.data?.token;
    if (token) {
      dispatch(setToken(token));
      form.setFieldsValue({ user: undefined, password: undefined });
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tokenRetreievedResult.data,
    tokenRetreievedResult.error,
    tokenRetreievedResult.loading,
  ]);

  const submitForm = async (values: any) => {
    const { database, user, password } = values;

    setFormIsLoading(true);
    setFormError(null);
    dispatch(setDatabase(database));
    loginAndGetToken({
      database,
      user,
      password,
    });
  };

  const onLoginWithOpenId = useCallback(async () => {
    setOpenIdLoading(true);
    signInOpenId?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    databases,
    databaseSelected,
    setDatabaseSelected,
    formError,
    formIsLoading,
    loading,
    loginMessage,
    openIdLoading,
    serverError,
    serverVersion,
    form,
    fetchData,
    submitForm,
    onLoginWithOpenId,
    setOpenIdLoading,
    mount,
  };
};
