import { useEffect } from "react";
import { dayjs } from "@gisce/react-ooui";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/ca";
import { Locale } from "@gisce/react-formiga-components";

const localeMapping: Record<Locale, string> = {
  es_ES: "es",
  en_US: "en",
  ca_ES: "ca",
};

export const useUpdateDayjsLocale = (locale: Locale) => {
  useEffect(() => {
    const dayjsLocale = localeMapping[locale];
    dayjs.locale(dayjsLocale);
  }, [locale]);
};
