import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Tooltip, Typography } from "antd";

import {
  CustomHost,
  setCurrentHost,
  useConnectionSettings,
} from "@/redux/slices/connectionSettingsSlice";

import { useLocale } from "@gisce/react-formiga-components";
import { useCallback, useMemo, useRef, useState } from "react";
import { EditCustomHostModal } from "./EditCustomHostModal";
import { useDispatch } from "react-redux";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";

const { Option } = Select;
const { Link } = Typography;

export const CustomHostsOptions = () => {
  const { t } = useLocale();
  const { currentHost, defaultHost, customHosts } = useConnectionSettings();
  const [editHostModalVisible, setEditHostModalVisible] =
    useState<boolean>(false);
  const hostToEditRef = useRef<CustomHost>();
  const dispatch = useDispatch();

  const HOST_LIST = useMemo(() => {
    // Start with the default host
    const list = [
      <Option key={"default"} value={defaultHost}>
        {t("default")}
      </Option>,
    ];

    // Add custom hosts
    customHosts.forEach((hostEntry) => {
      list.push(
        <Option key={hostEntry.name} value={hostEntry.url}>
          {hostEntry.name}
        </Option>,
      );
    });

    return list;
  }, [customHosts, defaultHost, t]); // Dependencies

  const onEditHost = useCallback(
    (hostToEdit: string) => {
      hostToEditRef.current = customHosts.find((hostEntry) => {
        return hostEntry.url === hostToEdit;
      });
      setEditHostModalVisible(true);
    },
    [customHosts],
  );

  const onAddNewHost = useCallback(() => {
    hostToEditRef.current = undefined;
    setEditHostModalVisible(true);
  }, []);

  const onChangeHost = useCallback(
    (host: string) => {
      if (host === currentHost) {
        return;
      }

      if (host === defaultHost) {
        dispatch(setCurrentHost(defaultHost));
        return;
      }

      const hostEntry = customHosts.find((hostEntry) => {
        return hostEntry.url === host;
      });

      if (!hostEntry) {
        return;
      }

      dispatch(setCurrentHost(hostEntry.url));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customHosts, defaultHost],
  );

  const currentHostValue = useMemo(() => {
    if (currentHost === defaultHost) {
      return defaultHost;
    }

    const hostEntry = customHosts.find((hostEntry) => {
      return hostEntry.url === currentHost;
    });

    if (!hostEntry) {
      return defaultHost;
    }

    return hostEntry.url;
  }, [currentHost, customHosts, defaultHost]);

  return (
    <ErrorBoundary>
      <Form.Item label={"Host"} name="host">
        <Row>
          <Col flex="auto">
            <Select
              size="large"
              className="flex justify-center w-full"
              value={currentHostValue}
              onChange={(value) => {
                onChangeHost(value);
              }}
            >
              {HOST_LIST}
            </Select>
          </Col>
          <Col
            style={{
              paddingLeft: 5,
            }}
          >
            {currentHost !== defaultHost && (
              <Tooltip title={t("editCustomHost")}>
                <Button
                  style={{ height: "100%", width: "35px" }}
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => {
                    onEditHost(currentHost);
                  }}
                />
              </Tooltip>
            )}
          </Col>
        </Row>
      </Form.Item>
      <Row justify="center" style={{ marginTop: -10, marginBottom: 10 }}>
        <Link href="#" onClick={onAddNewHost}>
          {t("addCustomHost")}
        </Link>
      </Row>
      <EditCustomHostModal
        visible={editHostModalVisible}
        onClose={() => setEditHostModalVisible(false)}
        hostEntry={hostToEditRef.current}
      />
    </ErrorBoundary>
  );
};
