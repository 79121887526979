import { useCallback, useEffect, useRef, useState } from "react";
import { PACKAGE_VERSION } from "@/config/constants";
import semver from "semver";
import Worker from "./versionCheckWorker?worker&inline";

export const useUpdateCheck = ({
  onUpdateAvailable,
  disabled,
}: UpdateHookParams) => {
  const [status, setStatus] = useState<UpdateStatus>(UpdateStatus.current);
  const workerRef = useRef<Worker>();
  const baseUrl = window.location.origin;
  const timerRef = useRef<any>();

  const reset = useCallback(() => {
    clearInterval(timerRef.current);
    workerRef.current?.terminate();
  }, []);

  useEffect(() => {
    if (!disabled) {
      return;
    }
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    if (disabled) {
      return;
    }

    if (workerRef.current) {
      reset();
    }

    workerRef.current = new Worker();

    workerRef.current.onmessage = (event: MessageEvent) => {
      const { type, version, error } = event.data;

      switch (type) {
        case "versionChecked":
          if (semver.gt(version, PACKAGE_VERSION)) {
            setStatus(UpdateStatus.available);
            onUpdateAvailable?.();
          } else {
            setStatus(UpdateStatus.current);
          }
          break;
        case "error":
          console.error("Update check failed:", error);
          setStatus(UpdateStatus.current);
          break;
      }
    };

    // First check just when the first time the component mounts
    workerRef.current.postMessage({ action: "checkVersion", baseUrl });

    // Schedule periodic checks
    timerRef.current = setInterval(
      () => {
        if (status === UpdateStatus.checking) return;
        workerRef.current?.postMessage({ action: "checkVersion", baseUrl });
      },
      5 * 60 * 1000,
    ); // Check every 5 minutes

    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUrl, onUpdateAvailable, status, disabled]);
};

export interface UpdateHookParams {
  onUpdateAvailable?: () => void;
  disabled?: boolean;
}

export enum UpdateStatus {
  checking = "checking",
  current = "current",
  available = "available",
}
