import BaseMenuEntry, {
  SubMenuEntryProps,
} from "@/components/menu/baseMenuEntry";
import { FolderOpenOutlined, FolderOutlined } from "@ant-design/icons";
import React from "react";
import { useMenuContext } from "./menuContext";

const SubMenuEntry = (props: SubMenuEntryProps): React.ReactElement => {
  const { color, id } = props;
  const { keyIsOpened } = useMenuContext();

  const DefaultIcon = keyIsOpened(id) ? FolderOpenOutlined : FolderOutlined;
  const { icon = <DefaultIcon className={`text-lg`} style={{ color }} /> } =
    props;

  return <BaseMenuEntry {...props} icon={icon} isExpandable={true} />;
};

export default SubMenuEntry;
