import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

interface Tab {
  id: string;
  action: string;
  //   type: string;
  //   title: string;
  //   model: string;
  //   resource_id?: string;
}

interface TabState {
  tabs: Tab[];
  activeTab: string | null;
}

const initialState: TabState = {
  tabs: [],
  activeTab: null,
};

export const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    addTab: (state, action: PayloadAction<string>) => {
      const newTab: Tab = {
        id: nanoid(),
        action: action.payload,
      };
      state.tabs.push(newTab);
      state.activeTab = newTab.id;
    },
    closeTab: (state, action: PayloadAction<string>) => {
      state.tabs = state.tabs.filter((tab) => tab.id !== action.payload);
      if (state.activeTab === action.payload) {
        state.activeTab = state.tabs[0]?.id || null;
      }
    },
    changeActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
  },
});

export const { addTab, closeTab, changeActiveTab } = tabSlice.actions;

export default tabSlice.reducer;
