import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { APIResult, useErpState } from "./erp/useErp";
import { setContext } from "@/redux/slices/userInfoSlice";
import { Context } from "@/types/base";

export const useHydrateContext = (): [
  APIResult<Context>,
  () => void,
  () => void,
] => {
  const dispatch = useDispatch();

  const [contextResult, fetchContext, cancelContextRequest] =
    useErpState("getContext");

  const { data } = contextResult;

  useEffect(() => {
    if (data) {
      dispatch(setContext(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return [contextResult, fetchContext, cancelContextRequest];
};
