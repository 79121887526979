import Tracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";
import trackerAxios from "@openreplay/tracker-axios";

import { getClient } from "./powerpClient";

export default class TrackService {
  private static instance: TrackService;

  private constructor() {}
  private tracker?: Tracker;
  public isRecording = false;

  public static getInstance(): TrackService {
    if (!TrackService.instance) {
      TrackService.instance = new TrackService();
    }

    return TrackService.instance;
  }

  public start({
    recordingName,
    apiKey,
    username,
  }: {
    recordingName: string;
    apiKey: string;
    username: string;
  }): void {
    if (this.isRecording) {
      return;
    }

    if (!this.tracker) {
      this.tracker = new Tracker({
        projectKey: apiKey,
        __DISABLE_SECURE_MODE: true,
        resourceBaseHref: "https://webclient-assets.gisce.net/",
      });
      this.tracker.use(
        trackerAxios({
          instance: getClient()!.getAxiosInstance(),
        }),
      );
      this.tracker.use(trackerAssist({}));
    }

    void this.tracker.start({
      userID: `${username} - ${recordingName}`,
      metadata: {
        username,
        recordingName,
      },
    });
    this.isRecording = true;
  }

  public stop(): void {
    if (!this.tracker || !this.isRecording) {
      return;
    }
    this.tracker.stop();
    this.isRecording = false;
  }
}
