import * as erpService from "@/services/erpService";
import * as shortcutService from "@/services/shortcutService";
import { RequestConfig, getRenewedToken } from "@/services/erpService";
import { useCallback, useEffect, useRef, useState } from "react";
import { useJwtToken } from "@/hooks/useJwtToken";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToken } from "@/redux/slices/connectionSettingsSlice";
import { AxiosError } from "axios";

type MethodsType = typeof erpService & typeof shortcutService;

export type PromiseType<T extends Promise<any>> = T extends Promise<infer U>
  ? U
  : never;

type PromiseValue<T> = T extends Promise<infer U> ? U : never;

export type ErpOptions = {
  auth?: boolean;
};

export type ErpStateOptions = ErpOptions & {
  initialState?: {
    data?: any | null;
    loading?: boolean;
    error?: AxiosError | null;
  };
};

export interface APIResult<T> {
  data?: T | null;
  loading: boolean;
  error: AxiosError | null;
}

export function useErp<Method extends keyof MethodsType>(
  method: Method,
  erpOptions?: ErpOptions,
): [
  (
    options?: Parameters<MethodsType[Method]>[0],
  ) => Promise<PromiseValue<ReturnType<MethodsType[Method]>>>,
  () => void,
] {
  const methods = {
    ...erpService,
    ...shortcutService,
  };

  const promise = async (
    options: Parameters<MethodsType[Method]>[0],
    requestConfig: RequestConfig,
  ): Promise<PromiseValue<ReturnType<MethodsType[Method]>>> => {
    return await methods[method](options as any, requestConfig);
  };

  return useFetch<Method>(promise, erpOptions);
}

export function useErpState<Method extends keyof MethodsType>(
  method: Method,
  erpOptions?: ErpStateOptions,
): [
  APIResult<PromiseValue<ReturnType<MethodsType[Method]>>>,
  (options?: Parameters<MethodsType[Method]>[0]) => void,
  () => void,
] {
  const [result, setResult] = useState<
    APIResult<PromiseValue<ReturnType<MethodsType[Method]>>>
  >({
    data: erpOptions?.initialState?.data || null,
    loading: erpOptions?.initialState?.loading || false,
    error: erpOptions?.initialState?.error || null,
  });

  const [fetchDataRaw, cancelRequest] = useErp(method, erpOptions);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchData = async (options?: Parameters<MethodsType[Method]>[0]) => {
    try {
      setResult((prev) => ({ data: null, error: null, loading: true }));
      const response = await fetchDataRaw(options);
      setResult({ data: response, loading: false, error: null });
    } catch (error) {
      if (error?.name === "CanceledError") {
        return;
      }
      if (!isMounted.current) {
        return;
      }
      setResult({
        data: null,
        loading: false,
        error,
      });
    }
  };

  return [result, fetchData, cancelRequest];
}

export function useFetch<Method extends keyof MethodsType>(
  request: (
    options: Parameters<MethodsType[Method]>[0],
    requestConfig: RequestConfig,
  ) => Promise<PromiseValue<ReturnType<MethodsType[Method]>>>,
  erpOptions?: ErpOptions,
): [
  (
    options?: Parameters<MethodsType[Method]>[0],
  ) => Promise<PromiseValue<ReturnType<MethodsType[Method]>>>,
  () => void,
] {
  const abortControllerRef = useRef<AbortController | null>(null);
  const { tokenIsAboutToExpire, token, tokenIsExpired } = useJwtToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const methods = {
    ...erpService,
    ...shortcutService,
  };

  const fetchData = useCallback(
    async (
      options?: Parameters<(typeof methods)[Method]>[0],
    ): Promise<PromiseType<ReturnType<(typeof methods)[Method]>>> => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      if (erpOptions?.auth !== false && tokenIsExpired && token) {
        navigate("/login");
        throw new Error("Token is expired");
      }
      if (
        erpOptions?.auth !== false &&
        tokenIsAboutToExpire === true &&
        token
      ) {
        const refreshedToken = await getRenewedToken(
          {
            token,
          },
          {
            signal: abortController.signal,
          },
        );
        dispatch(setToken(refreshedToken));
      }

      return await request(options, {
        signal: abortController.signal,
      });
    },
    [
      dispatch,
      erpOptions?.auth,
      navigate,
      request,
      token,
      tokenIsAboutToExpire,
      tokenIsExpired,
    ],
  );

  const cancelRequest = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }, []);

  return [fetchData, cancelRequest];
}
