import { useRemoteConfig } from "@/redux/slices/remoteConfigSlice";
import "@/tailwind.generated.css";
import { ConfigProvider, theme } from "antd";
import { useMemo } from "react";
import tinycolor from "tinycolor2";

export const Theme = ({ children }: { children: any }) => {
  const { themeMode, primaryColor } = useRemoteConfig();

  const lighterColor = useMemo(() => {
    const color = tinycolor(primaryColor);
    return color.getBrightness() > 80
      ? color.lighten(10).toHexString()
      : color.lighten(30).toHexString();
  }, [primaryColor]);

  return (
    <ConfigProvider
      theme={{
        algorithm: themeMode === "compact" ? theme.compactAlgorithm : undefined,
        token: {
          colorPrimary: lighterColor,
          colorInfo: lighterColor,
          colorPrimaryActive: primaryColor,
        },
        components: {
          Input: {
            colorTextDisabled: "#000000",
          },
          Select: {
            colorTextDisabled: "#000000",
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
