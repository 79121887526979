import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const PulseIcon = styled(Icon)`
  animation: ${pulse} 0.5s cubic-bezier(1, 0, 0, 1) infinite alternate;
`;

export const RecordingDotSvg = (): any => {
  return (
    <svg height="10" width="10">
      <circle cx="5" cy="5" r="4" fill="red" />
    </svg>
  );
};

export const RecordingDot = (props: Partial<CustomIconComponentProps>): any => {
  return <PulseIcon component={RecordingDotSvg} {...props} />;
};
