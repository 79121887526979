import { HEADER_PREFIX, PACKAGE_VERSION } from "@/config/constants";
import { useConnectionSettings } from "@/redux/slices/connectionSettingsSlice";
import { exportPowerpClientToWindow, getClient } from "@/services/powerpClient";
import { useEffect } from "react";
import { useLogout } from "./useLogout";

exportPowerpClientToWindow();

const usePowerpSetup = () => {
  const { currentHost, token, database } = useConnectionSettings();

  const logout = useLogout();

  useEffect(() => {
    getClient().setClientHeader(`${HEADER_PREFIX}/${PACKAGE_VERSION}`);
    getClient().setHost(currentHost);
    getClient().setOnTokenAccessDenied(() => {
      void logout();
    });
    token && getClient().setToken(token);
    database && getClient().setDatabase(database);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default usePowerpSetup;
