import { useErp } from "@/hooks/erp/useErp";
import { useUserInfo } from "@/redux/slices/userInfoSlice";
import { iconMapper } from "@gisce/react-ooui";
import { Empty, Select, Spin, Typography } from "antd";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UnorderedListOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Text } = Typography;

export type OmnisearchProps = {
  visible: boolean;
  onLaunch: (action: string) => void;
  onClose: () => void;
};

export const Omnisearch = ({ visible, onLaunch, onClose }: OmnisearchProps) => {
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        marginLeft: "auto",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <div style={{ width: "30%", minWidth: "500px" }}>
        <DebounceSelect
          mode="multiple"
          placeholder="Type something..."
          autoFocus
          size={"large"}
          style={{ width: "100%", marginBottom: "30%" }}
          value={[]}
          showArrow={false}
          onSelect={(opts: { value: string }) => {
            onLaunch(opts.value);
          }}
          onBlur={() => {
            if (!isMounted) {
              return;
            }
            onClose();
          }}
        />
      </div>
    </div>
  );
};

type OmnisearchResult = {
  id: number;
  name: string;
  icon: string;
  action: string;
  complete_name: string;
};

export function DebounceSelect(props: any) {
  const [options, setOptions] = useState<OmnisearchResult[]>();
  const [query, setQuery] = useState<string>();
  const { context } = useUserInfo();
  const { children, onBlur, ...rest } = props;
  const [loading, setLoading] = useState(false);

  const [fetchItems, cancelRequest] = useErp("execute");

  useEffect(() => {
    return () => {
      cancelRequest();
      debounceFetcher.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!query) {
      return;
    }
    if (query.trim().length <= 2) {
      return;
    }
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const items = await fetchItems({
        model: "ir.ui.menu",
        action: "omnisearch",
        context,
        payload: query,
      });
      setLoading(false);
      setOptions(
        items
          .filter((item: any) => item.action !== false)
          .sort((a: any, b: any) => {
            return a.name.length - b.name.length;
          }),
      );
    } catch (err) {
      setLoading(false);
      console.error(`Error fetching omnisearch items`, { err });
    }
  }, [context, fetchItems, query]);

  const debounceFetcher = useMemo(() => {
    return debounce((value: string) => {
      cancelRequest();
      setLoading(false);
      setOptions(undefined);
      setQuery(value.trim());
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noResults = !loading && query && options && options.length === 0;
  const notFoundContent = loading ? (
    <Spin size="small" style={{ paddingLeft: 5 }} />
  ) : noResults ? (
    <Empty
      description={`No results for "${query}"`}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  ) : null;

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={notFoundContent}
      onBlur={() => {
        setOptions(undefined);
        setQuery(undefined);
        onBlur();
      }}
      {...rest}
    >
      {(options || []).map((option) => {
        const Icon: any = iconMapper(option.icon, {
          className: `text-lg`,
          style: {},
        });
        const icon = (Icon && <Icon />) || (
          <UnorderedListOutlined className={`text-lg`} />
        );

        return (
          <Option key={option.id} value={option.action} label={option.name}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              <span role="img" aria-label={option.name}>
                {icon}
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <Text style={{ fontWeight: "bold" }}>{option.name}</Text>
                <Text ellipsis={{ tooltip: option.complete_name }}>
                  {option.complete_name}
                </Text>
              </div>
            </div>
          </Option>
        );
      })}
    </Select>
  );
}
