import { Client, Model, Report } from "@gisce/powerp.js";

const client = new Client();

export function getClient(): Client {
  return client;
}

export function exportPowerpClientToWindow() {
  // This is going to be handy in order to get the powerp client in the console browser
  (window as any).powerp = {
    client: getClient(),
    model: (model: string) => new Model(model, getClient()),
    report: () => new Report(getClient()),
  };
}
