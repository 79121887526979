import { Layout } from "antd";
import { MutableRefObject, useCallback, useState } from "react";

import Header from "@/components/common/Header";
import Sidebar, { SIDEBAR_WIDTH } from "@/components/containers/Sidebar";
import { useAuth } from "@/context/auth/AuthContext";
import { useSidebarCollapsed } from "@/hooks/useSidebarCollapsed";
import { useTheme } from "@/hooks/useTheme";
import { MenuItemUi } from "@/models/menuItem";
import { Content } from "antd/es/layout/layout";
import { PreferencesModal } from "../PreferencesModal";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { CurrentTabContent, TabSelector } from "@gisce/react-ooui";

export const MainContent = ({
  rootViewRef,
}: {
  rootViewRef: MutableRefObject<undefined>;
}) => {
  const { logout } = useAuth();
  const theme = useTheme();

  const {
    sidebarCollapsed,
    toggleSidebarCollapsed,
    widthBreakpointFired,
    closeSidebarIfNeeded,
  } = useSidebarCollapsed();
  const [showPreferences, setShowPreferences] = useState<boolean>(false);

  const onMenuItemClicked = useCallback(
    (menuItem: MenuItemUi) => {
      if (!menuItem.action) {
        return;
      }
      setShowPreferences(false);
      (rootViewRef.current as any).retrieveAndOpenAction({
        action: menuItem.action,
      });
      closeSidebarIfNeeded();
    },
    [closeSidebarIfNeeded, rootViewRef],
  );

  return (
    <>
      <Header
        onLogout={() => {
          void logout();
        }}
        sidebarCollapsed={sidebarCollapsed}
        onToggleMenu={toggleSidebarCollapsed}
        onPreferencesRequested={() => {
          setShowPreferences(true);
        }}
        tabSelector={<TabSelector />}
      />
      <Layout className="bg-white" style={{ marginTop: 64 }}>
        <ErrorBoundary>
          <Sidebar
            width={SIDEBAR_WIDTH}
            className={
              "h-full overflow-auto fixed transform ease-in-out transition-all duration-300 z-10 " +
              (sidebarCollapsed ? "-translate-x-full" : "translate-x-0")
            }
            style={{ backgroundColor: theme.colorPrimaryBg }}
            onMenuItemClicked={onMenuItemClicked}
          />
        </ErrorBoundary>
        <Layout
          style={{
            marginLeft: sidebarCollapsed
              ? 0
              : widthBreakpointFired
              ? 0
              : SIDEBAR_WIDTH,
          }}
          className="transition-all duration-300 ease-in-out bg-white"
        >
          <Content
            style={{
              margin: 5,
              marginTop: 10,
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >
            <ErrorBoundary>
              {/* <TabContent /> */}
              <CurrentTabContent />
              <PreferencesModal
                visible={showPreferences}
                close={() => setShowPreferences(false)}
              />
            </ErrorBoundary>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
