import { useLocale } from "@gisce/react-formiga-components";
import {
  CustomHost,
  addCustomHost,
  modifyCustomHost,
  removeCustomHost,
  setCurrentHost,
  useConnectionSettings,
} from "@/redux/slices/connectionSettingsSlice";
import { useDispatch } from "react-redux";
import { Button, Form, Input, Modal, Row } from "antd";
import { useCallback, useEffect } from "react";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
const { error } = Modal;

export type EditCustomHostModalProps = {
  hostEntry?: CustomHost;
  visible: boolean;
  onClose: () => void;
};

const DEFAULT_FORM_VALUES = {
  url: "",
  name: "",
};

export const EditCustomHostModal = (props: EditCustomHostModalProps): any => {
  const { visible, onClose, hostEntry } = props;
  const { t } = useLocale();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const showError = useCallback(
    () =>
      error({
        title: "Error",
        icon: <ExclamationCircleOutlined />,
        centered: true,
        content: t("errorHost"),
      }),
    [t],
  );

  const { currentHost, defaultHost, customHosts } = useConnectionSettings();

  const setInitialFields = useCallback(() => {
    if (!hostEntry) {
      form.setFieldsValue({ ...DEFAULT_FORM_VALUES });
      return;
    }

    form.setFieldsValue({
      url: hostEntry.url,
      name: hostEntry.name,
    });
  }, [form, hostEntry]);

  useEffect(() => {
    setInitialFields();
  }, [setInitialFields]);

  const removeHost = useCallback(() => {
    dispatch(removeCustomHost(hostEntry!.url));
    dispatch(setCurrentHost(defaultHost));
    onClose();
    form.setFieldsValue({ ...DEFAULT_FORM_VALUES });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostEntry, form]);

  const onFormFinish = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (verror) {
      return;
    }

    const values = form.getFieldsValue();

    if (values.url === currentHost) {
      showError();
      return;
    }

    if (!hostEntry && customHosts.some((h) => h.url === values.url)) {
      showError();
      return;
    }

    const hostEntryModified = { url: values.url, name: values.name };

    if (hostEntry) {
      dispatch(
        modifyCustomHost({ oldHost: hostEntry, newHost: hostEntryModified }),
      );
    } else {
      dispatch(addCustomHost(hostEntryModified));
    }

    dispatch(setCurrentHost(hostEntryModified.url));
    onClose();
    form.setFieldsValue({ ...DEFAULT_FORM_VALUES });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, hostEntry]);

  return (
    <Modal
      open={visible}
      title={hostEntry ? t("editCustomHost") : t("addCustomHost")}
      onCancel={onClose}
      centered
      onOk={() => {
        void onFormFinish();
      }}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("hostName")}
          name="name"
          rules={[{ required: true }]}
        >
          <Input size="large" disabled={!!hostEntry} />
        </Form.Item>
        <Form.Item
          label={t("host")}
          name="url"
          rules={[{ required: true, type: "url" }]}
        >
          <Input size="large" />
        </Form.Item>
      </Form>
      {hostEntry && (
        <Row style={{ justifyContent: "center" }}>
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={removeHost}
          >
            {t("removeCustomHost")}
          </Button>
        </Row>
      )}
    </Modal>
  );
};
