import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { APIResult, useErp } from "./erp/useErp";
import { setUser } from "@/redux/slices/userInfoSlice";
import { useUserId } from "./useUserId";
import { User } from "@/types/base";

export const useHydrateUser = (): [APIResult<User>, () => void, () => void] => {
  const dispatch = useDispatch();

  const [result, setResult] = useState<APIResult<User>>({
    data: null,
    loading: false,
    error: null,
  });

  const userId = useUserId();
  const [fetchUsers, cancelUsersRequest] = useErp("readObjects");

  const fetchData = useCallback(async () => {
    if (!userId) {
      return;
    }
    setResult({ loading: true, data: null, error: null });
    const users = await fetchUsers({
      ids: [userId],
      model: "res.users",
      fieldsToRetrieve: ["id", "login", "menu_id", "action_id"],
    });
    if (users?.length > 0) {
      dispatch(setUser(users[0]));
      setResult({ loading: false, data: users[0], error: null });
    }
  }, [dispatch, fetchUsers, userId]);

  return [result, fetchData, cancelUsersRequest];
};
