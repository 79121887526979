import { useRemoteConfig } from "@/redux/slices/remoteConfigSlice";
import { UserManager } from "oidc-client-ts";
import { useRef, useEffect, useCallback } from "react";

export const useOpenIdUserManager = () => {
  const userManagerRef = useRef<UserManager>();
  const { openidAuthorityUrl, openidClientId, openidFullLogout } =
    useRemoteConfig();

  useEffect(() => {
    if (openidAuthorityUrl && openidClientId) {
      userManagerRef.current = initUserManager({
        openidAuthorityUrl,
        openidClientId,
      });
    }
  }, [openidAuthorityUrl, openidClientId]);

  const getUserManager = useCallback(() => {
    if (!openidAuthorityUrl || !openidClientId) {
      throw new Error(
        "OpenID Authority URL and Client ID must be set in the remote config",
      );
    }
    if (!userManagerRef.current) {
      userManagerRef.current = initUserManager({
        openidAuthorityUrl,
        openidClientId,
      });
    }
    return userManagerRef.current;
  }, [openidAuthorityUrl, openidClientId]);

  return {
    getUserManager,
    openidAuthorityUrl,
    openidClientId,
    openidFullLogout,
  };
};

const initUserManager = ({
  openidAuthorityUrl,
  openidClientId,
}: {
  openidAuthorityUrl?: string;
  openidClientId?: string;
}): UserManager => {
  const redirectUri = window.location.origin + "/login";

  return new UserManager({
    authority: openidAuthorityUrl || "",
    client_id: openidClientId || "",
    redirect_uri: redirectUri || "",
    silent_redirect_uri: redirectUri,
    post_logout_redirect_uri: redirectUri,
    response_type: "code",
    scope: "openid profile email",
  });
};
