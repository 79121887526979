import { RootState, store } from "@/redux/store";
import { ErpFeatureKeys } from "@gisce/react-ooui";
import { useSelector } from "react-redux";

export const useErpFeatures = () => {
  return useSelector((state: RootState) => state.erpFeatures);
};

export const useFeatureEnabled = (feature: ErpFeatureKeys): boolean => {
  return useSelector((state: RootState) => state.erpFeatures[feature]) || false;
};

// TODO: Remove this method once we use always the useErp hook
export const isFeatureEnabled = (feature: ErpFeatureKeys): boolean => {
  const state = store.getState();
  return state.erpFeatures[feature] || false;
};

export const useFeatures = () => {
  return useSelector((state: RootState) => state.erpFeatures);
};
